body:not(#cacheContainer *) {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  margin: 0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

.ls-preview-tab {
  z-index: 999999;
  position: fixed;
  top: 75px;
  left: 100vw;
}

.ls-preview-tab-button {
  cursor: pointer;
  background-color: #fff;
  border: 2px solid #000;
  border-right: 0;
  border-radius: 5px 0 0 5px;
  width: 27px;
  height: 50px;
  position: absolute;
  top: 16px;
  left: -25px;
}

.ls-preview-tab-content {
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 8px;
  flex-direction: column;
  align-items: center;
  width: 250px;
  height: calc(100vh - 158px);
  padding: 16px 8px;
  display: flex;
}

.ls-preview-tab-status {
  background-color: #daa520;
  border-radius: 5px;
  margin-bottom: 16px;
  padding: 4px 8px;
  font-weight: bold;
}

.ls-preview-tab-badge, .ls-preview-tab-item {
  width: 100%;
  margin-bottom: 4px;
  display: flex;
}

.ls-preview-tab-badge-label {
  flex-grow: 1;
}

.ls-preview-tab-badge-value {
  border-radius: 5px;
  min-width: 25px;
  padding: 2px 4px;
  font-weight: bold;
}

.ls-preview-tab-item > div {
  flex-grow: 1;
}

.ls-preview-timer {
  height: 16px;
  padding: 4px;
  display: grid;
}

.ls-preview-timer-prog {
  z-index: 2;
  background-color: #000;
  grid-row-start: 1;
  grid-column-start: 1;
  height: 8px;
}

.ls-preview-timer-bar {
  background-color: #dcdcdc;
  grid-row-start: 1;
  grid-column-start: 1;
  width: 100%;
  height: 8px;
}

.ls-preview-tab-extra {
  width: 100%;
  margin-top: 2rem;
}

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*, :after, :before {
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  font-family: sans-serif;
  line-height: 1.15;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  color: #212529;
  text-align: left;
  background-color: #fff;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[data-original-title], abbr[title] {
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
  text-decoration: underline;
  border-bottom: 0;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

dl, ol, ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ol ul, ul ol, ul ul {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #007bff;
  background-color: #0000;
  text-decoration: none;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([tabindex]), a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

code, kbd, pre, samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  vertical-align: middle;
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
  padding-top: .75rem;
  padding-bottom: .75rem;
}

th {
  text-align: inherit;
}

label {
  margin-bottom: .5rem;
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

[type="button"], [type="reset"], [type="submit"], button {
  -webkit-appearance: button;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"], input[type="datetime-local"], input[type="month"], input[type="time"] {
  -webkit-appearance: listbox;
}

textarea {
  resize: vertical;
  overflow: auto;
}

fieldset {
  border: 0;
  min-width: 0;
  margin: 0;
  padding: 0;
}

legend {
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
  width: 100%;
  max-width: 100%;
  margin-bottom: .5rem;
  padding: 0;
  display: block;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  cursor: pointer;
  display: list-item;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  color: inherit;
  margin-bottom: .5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
}

.h1, h1 {
  font-size: 2.5rem;
}

.h2, h2 {
  font-size: 2rem;
}

.h3, h3 {
  font-size: 1.75rem;
}

.h4, h4 {
  font-size: 1.5rem;
}

.h5, h5 {
  font-size: 1.25rem;
}

.h6, h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  border: 0;
  border-top: 1px solid #0000001a;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.small, small {
  font-size: 80%;
  font-weight: 400;
}

.mark, mark {
  background-color: #fcf8e3;
  padding: .2em;
}

.list-unstyled, .list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.initialism {
  text-transform: uppercase;
  font-size: 90%;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  color: #6c757d;
  font-size: 80%;
  display: block;
}

.blockquote-footer:before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: .25rem;
  max-width: 100%;
  height: auto;
  padding: .25rem;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: .5rem;
  line-height: 1;
}

.figure-caption {
  color: #6c757d;
  font-size: 90%;
}

code {
  color: #e83e8c;
  word-break: break-word;
  font-size: 87.5%;
}

a > code {
  color: inherit;
}

kbd {
  color: #fff;
  background-color: #212529;
  border-radius: .2rem;
  padding: .2rem .4rem;
  font-size: 87.5%;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  color: #212529;
  font-size: 87.5%;
  display: block;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (width >= 576px) {
  .container {
    max-width: 540px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 720px;
  }
}

@media (width >= 992px) {
  .container {
    max-width: 960px;
  }
}

@media (width >= 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.row {
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
}

.no-gutters {
  margin-left: 0;
  margin-right: 0;
}

.no-gutters > .col, .no-gutters > [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-auto {
  flex: none;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.6667%;
  max-width: 16.6667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
}

.col-5 {
  flex: 0 0 41.6667%;
  max-width: 41.6667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333%;
  max-width: 58.3333%;
}

.col-8 {
  flex: 0 0 66.6667%;
  max-width: 66.6667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333%;
  max-width: 83.3333%;
}

.col-11 {
  flex: 0 0 91.6667%;
  max-width: 91.6667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.6667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333%;
}

.offset-5 {
  margin-left: 41.6667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333%;
}

.offset-8 {
  margin-left: 66.6667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333%;
}

.offset-11 {
  margin-left: 91.6667%;
}

@media (width >= 576px) {
  .col-sm {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-sm-auto {
    flex: none;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333%;
  }

  .offset-sm-5 {
    margin-left: 41.6667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333%;
  }

  .offset-sm-8 {
    margin-left: 66.6667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333%;
  }

  .offset-sm-11 {
    margin-left: 91.6667%;
  }
}

@media (width >= 768px) {
  .col-md {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-md-auto {
    flex: none;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-md-2 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .col-md-5 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
  }

  .col-md-8 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
  }

  .col-md-11 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.6667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333%;
  }

  .offset-md-5 {
    margin-left: 41.6667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333%;
  }

  .offset-md-8 {
    margin-left: 66.6667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333%;
  }

  .offset-md-11 {
    margin-left: 91.6667%;
  }
}

@media (width >= 992px) {
  .col-lg {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-lg-auto {
    flex: none;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.6667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333%;
  }

  .offset-lg-5 {
    margin-left: 41.6667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333%;
  }

  .offset-lg-8 {
    margin-left: 66.6667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333%;
  }

  .offset-lg-11 {
    margin-left: 91.6667%;
  }
}

@media (width >= 1200px) {
  .col-xl {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-xl-auto {
    flex: none;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.6667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333%;
  }

  .offset-xl-5 {
    margin-left: 41.6667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333%;
  }

  .offset-xl-8 {
    margin-left: 66.6667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333%;
  }

  .offset-xl-11 {
    margin-left: 91.6667%;
  }
}

.table {
  background-color: #0000;
  width: 100%;
  margin-bottom: 1rem;
}

.table td, .table th {
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  padding: .75rem;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table .table {
  background-color: #fff;
}

.table-sm td, .table-sm th {
  padding: .3rem;
}

.table-bordered, .table-bordered td, .table-bordered th {
  border: 1px solid #dee2e6;
}

.table-bordered thead td, .table-bordered thead th {
  border-bottom-width: 2px;
}

.table-borderless tbody + tbody, .table-borderless td, .table-borderless th, .table-borderless thead th {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #0000000d;
}

.table-hover tbody tr:hover {
  background-color: #00000013;
}

.table-primary, .table-primary > td, .table-primary > th {
  background-color: #b8daff;
}

.table-primary tbody + tbody, .table-primary td, .table-primary th, .table-primary thead th {
  border-color: #7abaff;
}

.table-hover .table-primary:hover, .table-hover .table-primary:hover > td, .table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary, .table-secondary > td, .table-secondary > th {
  background-color: #d6d8db;
}

.table-secondary tbody + tbody, .table-secondary td, .table-secondary th, .table-secondary thead th {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover, .table-hover .table-secondary:hover > td, .table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success, .table-success > td, .table-success > th {
  background-color: #c3e6cb;
}

.table-success tbody + tbody, .table-success td, .table-success th, .table-success thead th {
  border-color: #8fd19e;
}

.table-hover .table-success:hover, .table-hover .table-success:hover > td, .table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info, .table-info > td, .table-info > th {
  background-color: #bee5eb;
}

.table-info tbody + tbody, .table-info td, .table-info th, .table-info thead th {
  border-color: #86cfda;
}

.table-hover .table-info:hover, .table-hover .table-info:hover > td, .table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning, .table-warning > td, .table-warning > th {
  background-color: #ffeeba;
}

.table-warning tbody + tbody, .table-warning td, .table-warning th, .table-warning thead th {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover, .table-hover .table-warning:hover > td, .table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger, .table-danger > td, .table-danger > th {
  background-color: #f5c6cb;
}

.table-danger tbody + tbody, .table-danger td, .table-danger th, .table-danger thead th {
  border-color: #ed969e;
}

.table-hover .table-danger:hover, .table-hover .table-danger:hover > td, .table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light, .table-light > td, .table-light > th {
  background-color: #fdfdfe;
}

.table-light tbody + tbody, .table-light td, .table-light th, .table-light thead th {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover, .table-hover .table-light:hover > td, .table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark, .table-dark > td, .table-dark > th {
  background-color: #c6c8ca;
}

.table-dark tbody + tbody, .table-dark td, .table-dark th, .table-dark thead th {
  border-color: #95999c;
}

.table-hover .table-dark:hover, .table-hover .table-dark:hover > td, .table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active, .table-active > td, .table-active > th, .table-hover .table-active:hover, .table-hover .table-active:hover > td, .table-hover .table-active:hover > th {
  background-color: #00000013;
}

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #212529;
}

.table-dark td, .table-dark th, .table-dark thead th {
  border-color: #32383e;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: #ffffff0d;
}

.table-dark.table-hover tbody tr:hover {
  background-color: #ffffff13;
}

@media (width <= 575.98px) {
  .table-responsive-sm {
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    width: 100%;
    display: block;
    overflow-x: auto;
  }

  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (width <= 767.98px) {
  .table-responsive-md {
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    width: 100%;
    display: block;
    overflow-x: auto;
  }

  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (width <= 991.98px) {
  .table-responsive-lg {
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    width: 100%;
    display: block;
    overflow-x: auto;
  }

  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (width <= 1199.98px) {
  .table-responsive-xl {
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    width: 100%;
    display: block;
    overflow-x: auto;
  }

  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  width: 100%;
  display: block;
  overflow-x: auto;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media screen and (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: #0000;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  opacity: 1;
  background-color: #e9ecef;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file, .form-control-range {
  width: 100%;
  display: block;
}

.col-form-label {
  font-size: inherit;
  margin-bottom: 0;
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(.25rem + 1px);
  padding-bottom: calc(.25rem + 1px);
  font-size: .875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  color: #212529;
  background-color: #0000;
  border: 1px solid #0000;
  border-width: 1px 0;
  width: 100%;
  margin-bottom: 0;
  padding-top: .375rem;
  padding-bottom: .375rem;
  line-height: 1.5;
  display: block;
}

.form-control-plaintext.form-control-lg, .form-control-plaintext.form-control-sm {
  padding-left: 0;
  padding-right: 0;
}

.form-control-sm {
  border-radius: .2rem;
  height: calc(1.8125rem + 2px);
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
}

.form-control-lg {
  border-radius: .3rem;
  height: calc(2.875rem + 2px);
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

select.form-control[multiple], select.form-control[size], textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  margin-top: .25rem;
  display: block;
}

.form-row {
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  display: flex;
}

.form-row > .col, .form-row > [class*="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}

.form-check {
  padding-left: 1.25rem;
  display: block;
  position: relative;
}

.form-check-input {
  margin-top: .3rem;
  margin-left: -1.25rem;
  position: absolute;
}

.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  align-items: center;
  margin-right: .75rem;
  padding-left: 0;
  display: inline-flex;
}

.form-check-inline .form-check-input {
  margin-top: 0;
  margin-left: 0;
  margin-right: .3125rem;
  position: static;
}

.valid-feedback {
  color: #28a745;
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  display: none;
}

.valid-tooltip {
  z-index: 5;
  color: #fff;
  background-color: #28a745e6;
  border-radius: .25rem;
  max-width: 100%;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  display: none;
  position: absolute;
  top: 100%;
}

.form-control.is-valid, .was-validated .form-control:valid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right .5625rem center;
  background-repeat: no-repeat;
  background-size: 1.125rem 1.125rem;
  border-color: #28a745;
  padding-right: 2.25rem;
}

.form-control.is-valid:focus, .was-validated .form-control:valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 .2rem #28a74540;
}

.form-control.is-valid ~ .valid-feedback, .form-control.is-valid ~ .valid-tooltip, .was-validated .form-control:valid ~ .valid-feedback, .was-validated .form-control:valid ~ .valid-tooltip {
  display: block;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  background-position: right .5625rem top .5625rem;
  padding-right: 2.25rem;
}

.custom-select.is-valid, .was-validated .custom-select:valid {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right .75rem center / 8px 10px no-repeat, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") right 1.75rem center / 1.125rem 1.125rem no-repeat;
  border-color: #28a745;
  padding-right: 3.4375rem;
}

.custom-select.is-valid:focus, .was-validated .custom-select:valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 .2rem #28a74540;
}

.custom-select.is-valid ~ .valid-feedback, .custom-select.is-valid ~ .valid-tooltip, .was-validated .custom-select:valid ~ .valid-feedback, .was-validated .custom-select:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback, .form-control-file.is-valid ~ .valid-tooltip, .was-validated .form-control-file:valid ~ .valid-feedback, .was-validated .form-control-file:valid ~ .valid-tooltip {
  display: block;
}

.form-check-input.is-valid ~ .form-check-label, .was-validated .form-check-input:valid ~ .form-check-label {
  color: #28a745;
}

.form-check-input.is-valid ~ .valid-feedback, .form-check-input.is-valid ~ .valid-tooltip, .was-validated .form-check-input:valid ~ .valid-feedback, .was-validated .form-check-input:valid ~ .valid-tooltip {
  display: block;
}

.custom-control-input.is-valid ~ .custom-control-label, .was-validated .custom-control-input:valid ~ .custom-control-label {
  color: #28a745;
}

.custom-control-input.is-valid ~ .custom-control-label:before, .was-validated .custom-control-input:valid ~ .custom-control-label:before {
  border-color: #28a745;
}

.custom-control-input.is-valid ~ .valid-feedback, .custom-control-input.is-valid ~ .valid-tooltip, .was-validated .custom-control-input:valid ~ .valid-feedback, .was-validated .custom-control-input:valid ~ .valid-tooltip {
  display: block;
}

.custom-control-input.is-valid:checked ~ .custom-control-label:before, .was-validated .custom-control-input:valid:checked ~ .custom-control-label:before {
  background-color: #34ce57;
  border-color: #34ce57;
}

.custom-control-input.is-valid:focus ~ .custom-control-label:before, .was-validated .custom-control-input:valid:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 .2rem #28a74540;
}

.custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label:before, .was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label:before, .custom-file-input.is-valid ~ .custom-file-label, .was-validated .custom-file-input:valid ~ .custom-file-label {
  border-color: #28a745;
}

.custom-file-input.is-valid ~ .valid-feedback, .custom-file-input.is-valid ~ .valid-tooltip, .was-validated .custom-file-input:valid ~ .valid-feedback, .was-validated .custom-file-input:valid ~ .valid-tooltip {
  display: block;
}

.custom-file-input.is-valid:focus ~ .custom-file-label, .was-validated .custom-file-input:valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 .2rem #28a74540;
}

.invalid-feedback {
  color: #dc3545;
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  display: none;
}

.invalid-tooltip {
  z-index: 5;
  color: #fff;
  background-color: #dc3545e6;
  border-radius: .25rem;
  max-width: 100%;
  margin-top: .1rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  display: none;
  position: absolute;
  top: 100%;
}

.form-control.is-invalid, .was-validated .form-control:invalid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-position: right .5625rem center;
  background-repeat: no-repeat;
  background-size: 1.125rem 1.125rem;
  border-color: #dc3545;
  padding-right: 2.25rem;
}

.form-control.is-invalid:focus, .was-validated .form-control:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .2rem #dc354540;
}

.form-control.is-invalid ~ .invalid-feedback, .form-control.is-invalid ~ .invalid-tooltip, .was-validated .form-control:invalid ~ .invalid-feedback, .was-validated .form-control:invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  background-position: right .5625rem top .5625rem;
  padding-right: 2.25rem;
}

.custom-select.is-invalid, .was-validated .custom-select:invalid {
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right .75rem center / 8px 10px no-repeat, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") right 1.75rem center / 1.125rem 1.125rem no-repeat;
  border-color: #dc3545;
  padding-right: 3.4375rem;
}

.custom-select.is-invalid:focus, .was-validated .custom-select:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .2rem #dc354540;
}

.custom-select.is-invalid ~ .invalid-feedback, .custom-select.is-invalid ~ .invalid-tooltip, .was-validated .custom-select:invalid ~ .invalid-feedback, .was-validated .custom-select:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback, .form-control-file.is-invalid ~ .invalid-tooltip, .was-validated .form-control-file:invalid ~ .invalid-feedback, .was-validated .form-control-file:invalid ~ .invalid-tooltip {
  display: block;
}

.form-check-input.is-invalid ~ .form-check-label, .was-validated .form-check-input:invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-input.is-invalid ~ .invalid-feedback, .form-check-input.is-invalid ~ .invalid-tooltip, .was-validated .form-check-input:invalid ~ .invalid-feedback, .was-validated .form-check-input:invalid ~ .invalid-tooltip {
  display: block;
}

.custom-control-input.is-invalid ~ .custom-control-label, .was-validated .custom-control-input:invalid ~ .custom-control-label {
  color: #dc3545;
}

.custom-control-input.is-invalid ~ .custom-control-label:before, .was-validated .custom-control-input:invalid ~ .custom-control-label:before {
  border-color: #dc3545;
}

.custom-control-input.is-invalid ~ .invalid-feedback, .custom-control-input.is-invalid ~ .invalid-tooltip, .was-validated .custom-control-input:invalid ~ .invalid-feedback, .was-validated .custom-control-input:invalid ~ .invalid-tooltip {
  display: block;
}

.custom-control-input.is-invalid:checked ~ .custom-control-label:before, .was-validated .custom-control-input:invalid:checked ~ .custom-control-label:before {
  background-color: #e4606d;
  border-color: #e4606d;
}

.custom-control-input.is-invalid:focus ~ .custom-control-label:before, .was-validated .custom-control-input:invalid:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 .2rem #dc354540;
}

.custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label:before, .was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label:before, .custom-file-input.is-invalid ~ .custom-file-label, .was-validated .custom-file-input:invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.custom-file-input.is-invalid ~ .invalid-feedback, .custom-file-input.is-invalid ~ .invalid-tooltip, .was-validated .custom-file-input:invalid ~ .invalid-feedback, .was-validated .custom-file-input:invalid ~ .invalid-tooltip {
  display: block;
}

.custom-file-input.is-invalid:focus ~ .custom-file-label, .was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 .2rem #dc354540;
}

.form-inline {
  flex-flow: wrap;
  align-items: center;
  display: flex;
}

.form-inline .form-check {
  width: 100%;
}

@media (width >= 576px) {
  .form-inline label {
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    display: flex;
  }

  .form-inline .form-group {
    flex-flow: wrap;
    flex: none;
    align-items: center;
    margin-bottom: 0;
    display: flex;
  }

  .form-inline .form-control {
    vertical-align: middle;
    width: auto;
    display: inline-block;
  }

  .form-inline .form-control-plaintext {
    display: inline-block;
  }

  .form-inline .custom-select, .form-inline .input-group {
    width: auto;
  }

  .form-inline .form-check {
    justify-content: center;
    align-items: center;
    width: auto;
    padding-left: 0;
    display: flex;
  }

  .form-inline .form-check-input {
    margin-top: 0;
    margin-left: 0;
    margin-right: .25rem;
    position: relative;
  }

  .form-inline .custom-control {
    justify-content: center;
    align-items: center;
  }

  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: #0000;
  border: 1px solid #0000;
  border-radius: .25rem;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #212529;
  text-decoration: none;
}

.btn.focus, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.btn.disabled, .btn:disabled {
  opacity: .65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled, fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-primary.focus, .btn-primary:focus {
  box-shadow: 0 0 0 .2rem #268fff80;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary.focus, .btn-secondary:focus {
  box-shadow: 0 0 0 .2rem #828a9180;
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #828a9180;
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success.focus, .btn-success:focus {
  box-shadow: 0 0 0 .2rem #48b46180;
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled).active:focus, .btn-success:not(:disabled):not(.disabled):active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #48b46180;
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info.focus, .btn-info:focus {
  box-shadow: 0 0 0 .2rem #3ab0c380;
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled).active, .btn-info:not(:disabled):not(.disabled):active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled).active:focus, .btn-info:not(:disabled):not(.disabled):active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #3ab0c380;
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning.focus, .btn-warning:focus {
  box-shadow: 0 0 0 .2rem #deaa0c80;
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled).active, .btn-warning:not(:disabled):not(.disabled):active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled).active:focus, .btn-warning:not(:disabled):not(.disabled):active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #deaa0c80;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger.focus, .btn-danger:focus {
  box-shadow: 0 0 0 .2rem #e1536180;
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled).active, .btn-danger:not(:disabled):not(.disabled):active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled).active:focus, .btn-danger:not(:disabled):not(.disabled):active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #e1536180;
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light.focus, .btn-light:focus {
  box-shadow: 0 0 0 .2rem #d8d9db80;
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled):active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled).active:focus, .btn-light:not(:disabled):not(.disabled):active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #d8d9db80;
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark.focus, .btn-dark:focus {
  box-shadow: 0 0 0 .2rem #52585d80;
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled).active, .btn-dark:not(:disabled):not(.disabled):active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled).active:focus, .btn-dark:not(:disabled):not(.disabled):active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #52585d80;
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary.focus, .btn-outline-primary:focus {
  box-shadow: 0 0 0 .2rem #007bff80;
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: #0000;
}

.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus, .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #007bff80;
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary.focus, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 .2rem #6c757d80;
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: #0000;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary:not(:disabled):not(.disabled):active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #6c757d80;
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success.focus, .btn-outline-success:focus {
  box-shadow: 0 0 0 .2rem #28a74580;
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: #0000;
}

.btn-outline-success:not(:disabled):not(.disabled).active, .btn-outline-success:not(:disabled):not(.disabled):active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled).active:focus, .btn-outline-success:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #28a74580;
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info.focus, .btn-outline-info:focus {
  box-shadow: 0 0 0 .2rem #17a2b880;
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: #0000;
}

.btn-outline-info:not(:disabled):not(.disabled).active, .btn-outline-info:not(:disabled):not(.disabled):active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled).active:focus, .btn-outline-info:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #17a2b880;
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning.focus, .btn-outline-warning:focus {
  box-shadow: 0 0 0 .2rem #ffc10780;
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: #0000;
}

.btn-outline-warning:not(:disabled):not(.disabled).active, .btn-outline-warning:not(:disabled):not(.disabled):active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled).active:focus, .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #ffc10780;
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger.focus, .btn-outline-danger:focus {
  box-shadow: 0 0 0 .2rem #dc354580;
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: #0000;
}

.btn-outline-danger:not(:disabled):not(.disabled).active, .btn-outline-danger:not(:disabled):not(.disabled):active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled).active:focus, .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #dc354580;
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light.focus, .btn-outline-light:focus {
  box-shadow: 0 0 0 .2rem #f8f9fa80;
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: #0000;
}

.btn-outline-light:not(:disabled):not(.disabled).active, .btn-outline-light:not(:disabled):not(.disabled):active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled).active:focus, .btn-outline-light:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #f8f9fa80;
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark.focus, .btn-outline-dark:focus {
  box-shadow: 0 0 0 .2rem #343a4080;
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: #0000;
}

.btn-outline-dark:not(:disabled):not(.disabled).active, .btn-outline-dark:not(:disabled):not(.disabled):active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled).active:focus, .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #343a4080;
}

.btn-link {
  color: #007bff;
  font-weight: 400;
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}

.btn-link.focus, .btn-link:focus {
  box-shadow: none;
  text-decoration: underline;
}

.btn-link.disabled, .btn-link:disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-group-lg > .btn, .btn-lg {
  border-radius: .3rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.btn-group-sm > .btn, .btn-sm {
  border-radius: .2rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
}

.btn-block {
  width: 100%;
  display: block;
}

.btn-block + .btn-block {
  margin-top: .5rem;
}

input[type="button"].btn-block, input[type="reset"].btn-block, input[type="submit"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity .15s linear;
}

@media screen and (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  transition: height .35s;
  position: relative;
  overflow: hidden;
}

@media screen and (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropdown, .dropleft, .dropright, .dropup {
  position: relative;
}

.dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropdown-menu {
  z-index: 1000;
  float: left;
  color: #212529;
  text-align: left;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #00000026;
  border-radius: .25rem;
  min-width: 10rem;
  margin: .125rem 0 0;
  padding: .5rem 0;
  font-size: 1rem;
  list-style: none;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
}

.dropdown-menu-right {
  left: auto;
  right: 0;
}

@media (width >= 576px) {
  .dropdown-menu-sm-right {
    left: auto;
    right: 0;
  }
}

@media (width >= 768px) {
  .dropdown-menu-md-right {
    left: auto;
    right: 0;
  }
}

@media (width >= 992px) {
  .dropdown-menu-lg-right {
    left: auto;
    right: 0;
  }
}

@media (width >= 1200px) {
  .dropdown-menu-xl-right {
    left: auto;
    right: 0;
  }
}

.dropdown-menu-left {
  left: 0;
  right: auto;
}

@media (width >= 576px) {
  .dropdown-menu-sm-left {
    left: 0;
    right: auto;
  }
}

@media (width >= 768px) {
  .dropdown-menu-md-left {
    left: 0;
    right: auto;
  }
}

@media (width >= 992px) {
  .dropdown-menu-lg-left {
    left: 0;
    right: auto;
  }
}

@media (width >= 1200px) {
  .dropdown-menu-xl-left {
    left: 0;
    right: auto;
  }
}

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: .125rem;
  top: auto;
  bottom: 100%;
}

.dropup .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-top: 0;
  border-bottom-color: currentColor;
  margin-left: .255em;
  display: inline-block;
}

.dropup .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  margin-top: 0;
  margin-left: .125rem;
  top: 0;
  left: 100%;
  right: auto;
}

.dropright .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  border: .3em solid #0000;
  border-left-color: currentColor;
  border-right: 0;
  margin-left: .255em;
  display: inline-block;
}

.dropright .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropright .dropdown-toggle:after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  margin-top: 0;
  margin-right: .125rem;
  top: 0;
  left: auto;
  right: 100%;
}

.dropleft .dropdown-toggle:after {
  vertical-align: .255em;
  content: "";
  margin-left: .255em;
  display: none;
}

.dropleft .dropdown-toggle:before {
  vertical-align: .255em;
  content: "";
  border-top: .3em solid #0000;
  border-bottom: .3em solid #0000;
  border-right: .3em solid;
  margin-right: .255em;
  display: inline-block;
}

.dropleft .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropleft .dropdown-toggle:before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="top"] {
  bottom: auto;
  right: auto;
}

.dropdown-divider {
  border-top: 1px solid #e9ecef;
  height: 0;
  margin: .5rem 0;
  overflow: hidden;
}

.dropdown-item {
  clear: both;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: #0000;
  border: 0;
  width: 100%;
  padding: .25rem 1.5rem;
  font-weight: 400;
  display: block;
}

.dropdown-item:first-child {
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px);
}

.dropdown-item:last-child {
  border-bottom-right-radius: calc(.25rem - 1px);
  border-bottom-left-radius: calc(.25rem - 1px);
}

.dropdown-item:focus, .dropdown-item:hover {
  color: #16181b;
  background-color: #f8f9fa;
  text-decoration: none;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  background-color: #007bff;
  text-decoration: none;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #0000;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  color: #6c757d;
  white-space: nowrap;
  margin-bottom: 0;
  padding: .5rem 1.5rem;
  font-size: .875rem;
  display: block;
}

.dropdown-item-text {
  color: #212529;
  padding: .25rem 1.5rem;
  display: block;
}

.btn-group, .btn-group-vertical {
  vertical-align: middle;
  display: inline-flex;
  position: relative;
}

.btn-group-vertical > .btn, .btn-group > .btn {
  flex: auto;
  position: relative;
}

.btn-group-vertical > .btn:hover, .btn-group > .btn:hover, .btn-group-vertical > .btn.active, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn:focus, .btn-group > .btn.active, .btn-group > .btn:active, .btn-group > .btn:focus {
  z-index: 1;
}

.btn-toolbar {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn-group:not(:first-child), .btn-group > .btn:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn-group:not(:last-child) > .btn, .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn-group:not(:first-child) > .btn, .btn-group > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-left: .5625rem;
  padding-right: .5625rem;
}

.dropdown-toggle-split:after, .dropright .dropdown-toggle-split:after, .dropup .dropdown-toggle-split:after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split:before {
  margin-right: 0;
}

.btn-group-sm > .btn + .dropdown-toggle-split, .btn-sm + .dropdown-toggle-split {
  padding-left: .375rem;
  padding-right: .375rem;
}

.btn-group-lg > .btn + .dropdown-toggle-split, .btn-lg + .dropdown-toggle-split {
  padding-left: .75rem;
  padding-right: .75rem;
}

.btn-group-vertical {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.btn-group-vertical > .btn, .btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn-group:not(:first-child), .btn-group-vertical > .btn:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn-group:not(:last-child) > .btn, .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn-group:not(:first-child) > .btn, .btn-group-vertical > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn, .btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="checkbox"], .btn-group-toggle > .btn input[type="radio"], .btn-group-toggle > .btn-group > .btn input[type="checkbox"], .btn-group-toggle > .btn-group > .btn input[type="radio"] {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.input-group {
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  display: flex;
  position: relative;
}

.input-group > .custom-file, .input-group > .custom-select, .input-group > .form-control, .input-group > .form-control-plaintext {
  flex: auto;
  width: 1%;
  margin-bottom: 0;
  position: relative;
}

.input-group > .custom-file + .custom-file, .input-group > .custom-file + .custom-select, .input-group > .custom-file + .form-control, .input-group > .custom-select + .custom-file, .input-group > .custom-select + .custom-select, .input-group > .custom-select + .form-control, .input-group > .form-control + .custom-file, .input-group > .form-control + .custom-select, .input-group > .form-control + .form-control, .input-group > .form-control-plaintext + .custom-file, .input-group > .form-control-plaintext + .custom-select, .input-group > .form-control-plaintext + .form-control {
  margin-left: -1px;
}

.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label, .input-group > .custom-select:focus, .input-group > .form-control:focus {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .custom-select:not(:last-child), .input-group > .form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-select:not(:first-child), .input-group > .form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  align-items: center;
  display: flex;
}

.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label:after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-append, .input-group-prepend {
  display: flex;
}

.input-group-append .btn, .input-group-prepend .btn {
  z-index: 2;
  position: relative;
}

.input-group-append .btn:focus, .input-group-prepend .btn:focus {
  z-index: 3;
}

.input-group-append .btn + .btn, .input-group-append .btn + .input-group-text, .input-group-append .input-group-text + .btn, .input-group-append .input-group-text + .input-group-text, .input-group-prepend .btn + .btn, .input-group-prepend .btn + .input-group-text, .input-group-prepend .input-group-text + .btn, .input-group-prepend .input-group-text + .input-group-text {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  align-items: center;
  margin-bottom: 0;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
}

.input-group-text input[type="checkbox"], .input-group-text input[type="radio"] {
  margin-top: 0;
}

.input-group-lg > .custom-select, .input-group-lg > .form-control:not(textarea) {
  height: calc(2.875rem + 2px);
}

.input-group-lg > .custom-select, .input-group-lg > .form-control, .input-group-lg > .input-group-append > .btn, .input-group-lg > .input-group-append > .input-group-text, .input-group-lg > .input-group-prepend > .btn, .input-group-lg > .input-group-prepend > .input-group-text {
  border-radius: .3rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.input-group-sm > .custom-select, .input-group-sm > .form-control:not(textarea) {
  height: calc(1.8125rem + 2px);
}

.input-group-sm > .custom-select, .input-group-sm > .form-control, .input-group-sm > .input-group-append > .btn, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-prepend > .input-group-text {
  border-radius: .2rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
}

.input-group-lg > .custom-select, .input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle), .input-group > .input-group-append:last-child > .input-group-text:not(:last-child), .input-group > .input-group-append:not(:last-child) > .btn, .input-group > .input-group-append:not(:last-child) > .input-group-text, .input-group > .input-group-prepend > .btn, .input-group > .input-group-prepend > .input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn, .input-group > .input-group-append > .input-group-text, .input-group > .input-group-prepend:first-child > .btn:not(:first-child), .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child), .input-group > .input-group-prepend:not(:first-child) > .btn, .input-group > .input-group-prepend:not(:first-child) > .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  min-height: 1.5rem;
  padding-left: 1.5rem;
  display: block;
  position: relative;
}

.custom-control-inline {
  margin-right: 1rem;
  display: inline-flex;
}

.custom-control-input {
  z-index: -1;
  opacity: 0;
  position: absolute;
}

.custom-control-input:checked ~ .custom-control-label:before {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.custom-control-input:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 .2rem #007bff40;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label:before {
  border-color: #80bdff;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label:before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input:disabled ~ .custom-control-label:before {
  background-color: #e9ecef;
}

.custom-control-label {
  vertical-align: top;
  margin-bottom: 0;
  position: relative;
}

.custom-control-label:before {
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 1px solid #adb5bd;
  width: 1rem;
  height: 1rem;
  display: block;
  position: absolute;
  top: .25rem;
  left: -1.5rem;
}

.custom-control-label:after {
  content: "";
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50% 50%;
  width: 1rem;
  height: 1rem;
  display: block;
  position: absolute;
  top: .25rem;
  left: -1.5rem;
}

.custom-checkbox .custom-control-label:before {
  border-radius: .25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label:before {
  background-color: #007bff;
  border-color: #007bff;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label:before, .custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label:before {
  background-color: #007bff80;
}

.custom-radio .custom-control-label:before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label:before {
  background-color: #007bff80;
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label:before {
  pointer-events: all;
  border-radius: .5rem;
  width: 1.75rem;
  left: -2.25rem;
}

.custom-switch .custom-control-label:after {
  background-color: #adb5bd;
  border-radius: .5rem;
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out;
  top: calc(.25rem + 2px);
  left: calc(2px - 2.25rem);
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label:after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label:after {
  background-color: #fff;
  transform: translateX(.75rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label:before {
  background-color: #007bff80;
}

.custom-select {
  color: #495057;
  vertical-align: middle;
  appearance: none;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right .75rem center / 8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: .375rem 1.75rem .375rem .75rem;
  font-weight: 400;
  line-height: 1.5;
  display: inline-block;
}

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 .2rem #80bdff80;
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  background-image: none;
  height: auto;
  padding-right: .75rem;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  opacity: 0;
}

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
  font-size: .875rem;
}

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0;
  display: inline-block;
  position: relative;
}

.custom-file-input {
  z-index: 2;
  opacity: 0;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  position: relative;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label:after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]:after {
  content: attr(data-browse);
}

.custom-file-label {
  z-index: 1;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  height: calc(2.25rem + 2px);
  padding: .375rem .75rem;
  font-weight: 400;
  line-height: 1.5;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.custom-file-label:after {
  z-index: 3;
  color: #495057;
  content: "Browse";
  border-left: inherit;
  background-color: #e9ecef;
  border-radius: 0 .25rem .25rem 0;
  height: 2.25rem;
  padding: .375rem .75rem;
  line-height: 1.5;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.custom-range {
  appearance: none;
  background-color: #0000;
  width: 100%;
  height: 1.4rem;
  padding: 0;
}

.custom-range:focus {
  outline: 0;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem #007bff40;
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem #007bff40;
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem #007bff40;
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  appearance: none;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  width: 1rem;
  height: 1rem;
  margin-top: -.25rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-webkit-slider-runnable-track {
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
  width: 100%;
  height: .5rem;
}

.custom-range::-moz-range-thumb {
  appearance: none;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  width: 1rem;
  height: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-moz-range-track {
  color: #0000;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: #0000;
  border-radius: 1rem;
  width: 100%;
  height: .5rem;
}

.custom-range::-ms-thumb {
  appearance: none;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-left: .2rem;
  margin-right: .2rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-ms-track {
  color: #0000;
  cursor: pointer;
  background-color: #0000;
  border-width: .5rem;
  border-color: #0000;
  width: 100%;
  height: .5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  background-color: #dee2e6;
  border-radius: 1rem;
  margin-right: 15px;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label:before, .custom-file-label, .custom-select {
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-control-label:before, .custom-file-label, .custom-select {
    transition: none;
  }
}

.nav {
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.nav-link {
  padding: .5rem 1rem;
  display: block;
}

.nav-link:focus, .nav-link:hover {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid #0000;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: #0000;
  border-color: #0000;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -1px;
}

.nav-pills .nav-link {
  border-radius: .25rem;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill .nav-item {
  text-align: center;
  flex: auto;
}

.nav-justified .nav-item {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: .5rem 1rem;
  display: flex;
  position: relative;
}

.navbar > .container, .navbar > .container-fluid {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.navbar-brand {
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
  margin-right: 1rem;
  padding-top: .3125rem;
  padding-bottom: .3125rem;
  display: inline-block;
}

.navbar-brand:focus, .navbar-brand:hover {
  text-decoration: none;
}

.navbar-nav {
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.navbar-nav .nav-link {
  padding-left: 0;
  padding-right: 0;
}

.navbar-nav .dropdown-menu {
  float: none;
  position: static;
}

.navbar-text {
  padding-top: .5rem;
  padding-bottom: .5rem;
  display: inline-block;
}

.navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

.navbar-toggler {
  background-color: #0000;
  border: 1px solid #0000;
  border-radius: .25rem;
  padding: .25rem .75rem;
  font-size: 1.25rem;
  line-height: 1;
}

.navbar-toggler:focus, .navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.navbar-toggler-icon {
  vertical-align: middle;
  content: "";
  background: center / 100% 100% no-repeat;
  width: 1.5em;
  height: 1.5em;
  display: inline-block;
}

@media (width <= 575.98px) {
  .navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (width >= 576px) {
  .navbar-expand-sm {
    flex-flow: row;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-sm .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (width <= 767.98px) {
  .navbar-expand-md > .container, .navbar-expand-md > .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (width >= 768px) {
  .navbar-expand-md {
    flex-flow: row;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-md > .container, .navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-md .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (width <= 991.98px) {
  .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (width >= 992px) {
  .navbar-expand-lg {
    flex-flow: row;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-lg .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (width <= 1199.98px) {
  .navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (width >= 1200px) {
  .navbar-expand-xl {
    flex-flow: row;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-xl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row;
  justify-content: flex-start;
}

.navbar-expand > .container, .navbar-expand > .container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-left: .5rem;
  padding-right: .5rem;
}

.navbar-expand > .container, .navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand, .navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
  color: #000000e6;
}

.navbar-light .navbar-nav .nav-link {
  color: #00000080;
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: #000000b3;
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: #0000004d;
}

.navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show > .nav-link {
  color: #000000e6;
}

.navbar-light .navbar-toggler {
  color: #00000080;
  border-color: #0000001a;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: #00000080;
}

.navbar-light .navbar-text a, .navbar-light .navbar-text a:focus, .navbar-light .navbar-text a:hover {
  color: #000000e6;
}

.navbar-dark .navbar-brand, .navbar-dark .navbar-brand:focus, .navbar-dark .navbar-brand:hover {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: #ffffff80;
}

.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
  color: #ffffffbf;
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: #ffffff40;
}

.navbar-dark .navbar-nav .active > .nav-link, .navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .show > .nav-link {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: #ffffff80;
  border-color: #ffffff1a;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: #ffffff80;
}

.navbar-dark .navbar-text a, .navbar-dark .navbar-text a:focus, .navbar-dark .navbar-text a:hover {
  color: #fff;
}

.card {
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #00000020;
  border-radius: .25rem;
  flex-direction: column;
  min-width: 0;
  display: flex;
  position: relative;
}

.card > hr {
  margin-left: 0;
  margin-right: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.card-body {
  flex: auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: .75rem;
}

.card-subtitle {
  margin-top: -.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  color: inherit;
  background-color: #00000008;
  border-bottom: 1px solid #00000020;
  margin-bottom: 0;
  padding: .75rem 1.25rem;
}

.card-header:first-child {
  border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  background-color: #00000008;
  border-top: 1px solid #00000020;
  padding: .75rem 1.25rem;
}

.card-footer:last-child {
  border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px);
}

.card-header-tabs {
  border-bottom: 0;
  margin-bottom: -.75rem;
  margin-left: -.625rem;
  margin-right: -.625rem;
}

.card-header-pills {
  margin-left: -.625rem;
  margin-right: -.625rem;
}

.card-img-overlay {
  padding: 1.25rem;
  position: absolute;
  inset: 0;
}

.card-img {
  border-radius: calc(.25rem - 1px);
  width: 100%;
}

.card-img-top {
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px);
  width: 100%;
}

.card-img-bottom {
  border-bottom-right-radius: calc(.25rem - 1px);
  border-bottom-left-radius: calc(.25rem - 1px);
  width: 100%;
}

.card-deck {
  flex-direction: column;
  display: flex;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (width >= 576px) {
  .card-deck {
    flex-flow: wrap;
    margin-left: -15px;
    margin-right: -15px;
  }

  .card-deck .card {
    flex-direction: column;
    flex: 1 0;
    margin-bottom: 0;
    margin-left: 15px;
    margin-right: 15px;
    display: flex;
  }
}

.card-group {
  flex-direction: column;
  display: flex;
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (width >= 576px) {
  .card-group {
    flex-flow: wrap;
  }

  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    border-left: 0;
    margin-left: 0;
  }

  .card-group > .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:first-child .card-header, .card-group > .card:first-child .card-img-top {
    border-top-right-radius: 0;
  }

  .card-group > .card:first-child .card-footer, .card-group > .card:first-child .card-img-bottom {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:last-child .card-header, .card-group > .card:last-child .card-img-top {
    border-top-left-radius: 0;
  }

  .card-group > .card:last-child .card-footer, .card-group > .card:last-child .card-img-bottom {
    border-bottom-left-radius: 0;
  }

  .card-group > .card:only-child {
    border-radius: .25rem;
  }

  .card-group > .card:only-child .card-header, .card-group > .card:only-child .card-img-top {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
  }

  .card-group > .card:only-child .card-footer, .card-group > .card:only-child .card-img-bottom {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem;
  }

  .card-group > .card:not(:first-child):not(:last-child):not(:only-child), .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer, .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header, .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom, .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top {
    border-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: .75rem;
}

@media (width >= 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
    column-gap: 1.25rem;
  }

  .card-columns .card {
    width: 100%;
    display: inline-block;
  }
}

.accordion .card {
  overflow: hidden;
}

.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}

.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}

.accordion .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion .card .card-header {
  margin-bottom: -1px;
}

.breadcrumb {
  background-color: #e9ecef;
  border-radius: .25rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  padding: .75rem 1rem;
  list-style: none;
  display: flex;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: .5rem;
}

.breadcrumb-item + .breadcrumb-item:before {
  color: #6c757d;
  content: "/";
  padding-right: .5rem;
  display: inline-block;
}

.breadcrumb-item + .breadcrumb-item:hover:before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  border-radius: .25rem;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.page-link {
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
  margin-left: -1px;
  padding: .5rem .75rem;
  line-height: 1.25;
  display: block;
  position: relative;
}

.page-link:hover {
  z-index: 2;
  color: #0056b3;
  background-color: #e9ecef;
  border-color: #dee2e6;
  text-decoration: none;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.page-item:first-child .page-link {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
  margin-left: 0;
}

.page-item:last-child .page-link {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: .75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: .3rem;
  border-bottom-left-radius: .3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: .3rem;
  border-bottom-right-radius: .3rem;
}

.pagination-sm .page-link {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: .2rem;
  border-bottom-left-radius: .2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: .2rem;
  border-bottom-right-radius: .2rem;
}

.badge {
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
  padding: .25em .4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  display: inline-block;
}

a.badge:focus, a.badge:hover {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  border-radius: 10rem;
  padding-left: .6em;
  padding-right: .6em;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}

a.badge-primary:focus, a.badge-primary:hover {
  color: #fff;
  background-color: #0062cc;
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

a.badge-secondary:focus, a.badge-secondary:hover {
  color: #fff;
  background-color: #545b62;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

a.badge-success:focus, a.badge-success:hover {
  color: #fff;
  background-color: #1e7e34;
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

a.badge-info:focus, a.badge-info:hover {
  color: #fff;
  background-color: #117a8b;
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

a.badge-warning:focus, a.badge-warning:hover {
  color: #212529;
  background-color: #d39e00;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

a.badge-danger:focus, a.badge-danger:hover {
  color: #fff;
  background-color: #bd2130;
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

a.badge-light:focus, a.badge-light:hover {
  color: #212529;
  background-color: #dae0e5;
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

a.badge-dark:focus, a.badge-dark:hover {
  color: #fff;
  background-color: #1d2124;
}

.jumbotron {
  background-color: #e9ecef;
  border-radius: .3rem;
  margin-bottom: 2rem;
  padding: 2rem 1rem;
}

@media (width >= 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
}

.alert {
  border: 1px solid #0000;
  border-radius: .25rem;
  margin-bottom: 1rem;
  padding: .75rem 1.25rem;
  position: relative;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  color: inherit;
  padding: .75rem 1.25rem;
  position: absolute;
  top: 0;
  right: 0;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.alert-primary hr {
  border-top-color: #9fcdff;
}

.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-secondary hr {
  border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-success hr {
  border-top-color: #b1dfbb;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

.progress {
  background-color: #e9ecef;
  border-radius: .25rem;
  height: 1rem;
  font-size: .75rem;
  display: flex;
  overflow: hidden;
}

.progress-bar {
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  flex-direction: column;
  justify-content: center;
  transition: width .6s;
  display: flex;
}

@media screen and (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

.media {
  align-items: flex-start;
  display: flex;
}

.media-body {
  flex: 1;
}

.list-group {
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.list-group-item-action {
  color: #495057;
  text-align: inherit;
  width: 100%;
}

.list-group-item-action:focus, .list-group-item-action:hover {
  color: #495057;
  background-color: #f8f9fa;
  text-decoration: none;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  background-color: #fff;
  border: 1px solid #00000020;
  margin-bottom: -1px;
  padding: .75rem 1.25rem;
  display: block;
  position: relative;
}

.list-group-item:first-child {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.list-group-item:last-child {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
  margin-bottom: 0;
}

.list-group-item:focus, .list-group-item:hover {
  z-index: 1;
  text-decoration: none;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.list-group-flush .list-group-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

.list-group-flush .list-group-item:last-child {
  margin-bottom: -1px;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
  margin-bottom: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}

.list-group-item-primary.list-group-item-action:focus, .list-group-item-primary.list-group-item-action:hover {
  color: #004085;
  background-color: #9fcdff;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:focus, .list-group-item-secondary.list-group-item-action:hover {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

.list-group-item-success.list-group-item-action:focus, .list-group-item-success.list-group-item-action:hover {
  color: #155724;
  background-color: #b1dfbb;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:focus, .list-group-item-info.list-group-item-action:hover {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:focus, .list-group-item-warning.list-group-item-action:hover {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

.list-group-item-danger.list-group-item-action:focus, .list-group-item-danger.list-group-item-action:hover {
  color: #721c24;
  background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:focus, .list-group-item-light.list-group-item-action:hover {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:focus, .list-group-item-dark.list-group-item-action:hover {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  color: #000;
  text-shadow: 0 1px #fff;
  opacity: .5;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.close:not(:disabled):not(.disabled):focus, .close:not(:disabled):not(.disabled):hover {
  opacity: .75;
}

button.close {
  appearance: none;
  background-color: #0000;
  border: 0;
  padding: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  backdrop-filter: blur(10px);
  opacity: 0;
  background-color: #ffffffd9;
  background-clip: padding-box;
  border: 1px solid #0000001a;
  border-radius: .25rem;
  max-width: 350px;
  font-size: .875rem;
  overflow: hidden;
  box-shadow: 0 .25rem .75rem #0000001a;
}

.toast:not(:last-child) {
  margin-bottom: .75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  opacity: 1;
  display: block;
}

.toast.hide {
  display: none;
}

.toast-header {
  color: #6c757d;
  background-color: #ffffffd9;
  background-clip: padding-box;
  border-bottom: 1px solid #0000000d;
  align-items: center;
  padding: .25rem .75rem;
  display: flex;
}

.toast-body {
  padding: .75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow: hidden auto;
}

.modal {
  z-index: 1050;
  outline: 0;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.modal-dialog {
  pointer-events: none;
  width: auto;
  margin: .5rem;
  position: relative;
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out, -webkit-transform .3s ease-out;
  transform: translate(0, -50px);
}

@media screen and (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal-dialog-centered {
  align-items: center;
  min-height: calc(100% - 1rem);
  display: flex;
}

.modal-dialog-centered:before {
  content: "";
  height: calc(100vh - 1rem);
  display: block;
}

.modal-content {
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #0003;
  border-radius: .3rem;
  outline: 0;
  flex-direction: column;
  width: 100%;
  display: flex;
  position: relative;
}

.modal-backdrop {
  z-index: 1040;
  background-color: #000;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: .5;
}

.modal-header {
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem;
  display: flex;
}

.modal-header .close {
  margin: -1rem -1rem -1rem auto;
  padding: 1rem;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  flex: auto;
  padding: 1rem;
  position: relative;
}

.modal-footer {
  border-top: 1px solid #e9ecef;
  border-bottom-right-radius: .3rem;
  border-bottom-left-radius: .3rem;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
  display: flex;
}

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  width: 50px;
  height: 50px;
  position: absolute;
  top: -9999px;
  overflow: scroll;
}

@media (width >= 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered:before {
    height: calc(100vh - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (width >= 992px) {
  .modal-lg, .modal-xl {
    max-width: 800px;
  }
}

@media (width >= 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  z-index: 1070;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  word-wrap: break-word;
  opacity: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: .875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: none;
  display: block;
  position: absolute;
}

.tooltip.show {
  opacity: .9;
}

.tooltip .arrow {
  width: .8rem;
  height: .4rem;
  display: block;
  position: absolute;
}

.tooltip .arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.bs-tooltip-auto[x-placement^="top"], .bs-tooltip-top {
  padding: .4rem 0;
}

.bs-tooltip-auto[x-placement^="top"] .arrow, .bs-tooltip-top .arrow {
  bottom: 0;
}

.bs-tooltip-auto[x-placement^="top"] .arrow:before, .bs-tooltip-top .arrow:before {
  border-width: .4rem .4rem 0;
  border-top-color: #000;
  top: 0;
}

.bs-tooltip-auto[x-placement^="right"], .bs-tooltip-right {
  padding: 0 .4rem;
}

.bs-tooltip-auto[x-placement^="right"] .arrow, .bs-tooltip-right .arrow {
  width: .4rem;
  height: .8rem;
  left: 0;
}

.bs-tooltip-auto[x-placement^="right"] .arrow:before, .bs-tooltip-right .arrow:before {
  border-width: .4rem .4rem .4rem 0;
  border-right-color: #000;
  right: 0;
}

.bs-tooltip-auto[x-placement^="bottom"], .bs-tooltip-bottom {
  padding: .4rem 0;
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow, .bs-tooltip-bottom .arrow {
  top: 0;
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow:before, .bs-tooltip-bottom .arrow:before {
  border-width: 0 .4rem .4rem;
  border-bottom-color: #000;
  bottom: 0;
}

.bs-tooltip-auto[x-placement^="left"], .bs-tooltip-left {
  padding: 0 .4rem;
}

.bs-tooltip-auto[x-placement^="left"] .arrow, .bs-tooltip-left .arrow {
  width: .4rem;
  height: .8rem;
  right: 0;
}

.bs-tooltip-auto[x-placement^="left"] .arrow:before, .bs-tooltip-left .arrow:before {
  border-width: .4rem 0 .4rem .4rem;
  border-left-color: #000;
  left: 0;
}

.tooltip-inner {
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: .25rem;
  max-width: 200px;
  padding: .25rem .5rem;
}

.popover {
  z-index: 1060;
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #0003;
  border-radius: .3rem;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: .875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: none;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.popover .arrow {
  width: 1rem;
  height: .5rem;
  margin: 0 .3rem;
  display: block;
  position: absolute;
}

.popover .arrow:after, .popover .arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  display: block;
  position: absolute;
}

.bs-popover-auto[x-placement^="top"], .bs-popover-top {
  margin-bottom: .5rem;
}

.bs-popover-auto[x-placement^="top"] .arrow, .bs-popover-top .arrow {
  bottom: calc(-.5rem - 1px);
}

.bs-popover-auto[x-placement^="top"] .arrow:after, .bs-popover-auto[x-placement^="top"] .arrow:before, .bs-popover-top .arrow:after, .bs-popover-top .arrow:before {
  border-width: .5rem .5rem 0;
}

.bs-popover-auto[x-placement^="top"] .arrow:before, .bs-popover-top .arrow:before {
  border-top-color: #00000040;
  bottom: 0;
}

.bs-popover-auto[x-placement^="top"] .arrow:after, .bs-popover-top .arrow:after {
  border-top-color: #fff;
  bottom: 1px;
}

.bs-popover-auto[x-placement^="right"], .bs-popover-right {
  margin-left: .5rem;
}

.bs-popover-auto[x-placement^="right"] .arrow, .bs-popover-right .arrow {
  width: .5rem;
  height: 1rem;
  margin: .3rem 0;
  left: calc(-.5rem - 1px);
}

.bs-popover-auto[x-placement^="right"] .arrow:after, .bs-popover-auto[x-placement^="right"] .arrow:before, .bs-popover-right .arrow:after, .bs-popover-right .arrow:before {
  border-width: .5rem .5rem .5rem 0;
}

.bs-popover-auto[x-placement^="right"] .arrow:before, .bs-popover-right .arrow:before {
  border-right-color: #00000040;
  left: 0;
}

.bs-popover-auto[x-placement^="right"] .arrow:after, .bs-popover-right .arrow:after {
  border-right-color: #fff;
  left: 1px;
}

.bs-popover-auto[x-placement^="bottom"], .bs-popover-bottom {
  margin-top: .5rem;
}

.bs-popover-auto[x-placement^="bottom"] .arrow, .bs-popover-bottom .arrow {
  top: calc(-.5rem - 1px);
}

.bs-popover-auto[x-placement^="bottom"] .arrow:after, .bs-popover-auto[x-placement^="bottom"] .arrow:before, .bs-popover-bottom .arrow:after, .bs-popover-bottom .arrow:before {
  border-width: 0 .5rem .5rem;
}

.bs-popover-auto[x-placement^="bottom"] .arrow:before, .bs-popover-bottom .arrow:before {
  border-bottom-color: #00000040;
  top: 0;
}

.bs-popover-auto[x-placement^="bottom"] .arrow:after, .bs-popover-bottom .arrow:after {
  border-bottom-color: #fff;
  top: 1px;
}

.bs-popover-auto[x-placement^="bottom"] .popover-header:before, .bs-popover-bottom .popover-header:before {
  content: "";
  border-bottom: 1px solid #f7f7f7;
  width: 1rem;
  margin-left: -.5rem;
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.bs-popover-auto[x-placement^="left"], .bs-popover-left {
  margin-right: .5rem;
}

.bs-popover-auto[x-placement^="left"] .arrow, .bs-popover-left .arrow {
  width: .5rem;
  height: 1rem;
  margin: .3rem 0;
  right: calc(-.5rem - 1px);
}

.bs-popover-auto[x-placement^="left"] .arrow:after, .bs-popover-auto[x-placement^="left"] .arrow:before, .bs-popover-left .arrow:after, .bs-popover-left .arrow:before {
  border-width: .5rem 0 .5rem .5rem;
}

.bs-popover-auto[x-placement^="left"] .arrow:before, .bs-popover-left .arrow:before {
  border-left-color: #00000040;
  right: 0;
}

.bs-popover-auto[x-placement^="left"] .arrow:after, .bs-popover-left .arrow:after {
  border-left-color: #fff;
  right: 1px;
}

.popover-header {
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px);
  margin-bottom: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  color: #212529;
  padding: .5rem .75rem;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.carousel-inner:after {
  clear: both;
  content: "";
  display: block;
}

.carousel-item {
  float: left;
  backface-visibility: hidden;
  width: 100%;
  margin-right: -100%;
  transition: transform .6s ease-in-out, -webkit-transform .6s ease-in-out;
  display: none;
  position: relative;
}

@media screen and (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item-next, .carousel-item-prev, .carousel-item.active {
  display: block;
}

.active.carousel-item-right, .carousel-item-next:not(.carousel-item-left) {
  transform: translateX(100%);
}

.active.carousel-item-left, .carousel-item-prev:not(.carousel-item-right) {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item-next.carousel-item-left, .carousel-fade .carousel-item-prev.carousel-item-right, .carousel-fade .carousel-item.active {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left, .carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left, .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-next, .carousel-control-prev {
  z-index: 1;
  color: #fff;
  text-align: center;
  opacity: .5;
  justify-content: center;
  align-items: center;
  width: 15%;
  transition: opacity .15s;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

@media screen and (prefers-reduced-motion: reduce) {
  .carousel-control-next, .carousel-control-prev {
    transition: none;
  }
}

.carousel-control-next:focus, .carousel-control-next:hover, .carousel-control-prev:focus, .carousel-control-prev:hover {
  color: #fff;
  opacity: .9;
  outline: 0;
  text-decoration: none;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-next-icon, .carousel-control-prev-icon {
  background: center / 100% 100% no-repeat;
  width: 20px;
  height: 20px;
  display: inline-block;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  z-index: 15;
  justify-content: center;
  margin-left: 15%;
  margin-right: 15%;
  padding-left: 0;
  list-style: none;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.carousel-indicators li {
  box-sizing: content-box;
  text-indent: -999px;
  cursor: pointer;
  opacity: .5;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid #0000;
  border-bottom: 10px solid #0000;
  flex: 0 auto;
  width: 30px;
  height: 3px;
  margin-left: 3px;
  margin-right: 3px;
  transition: opacity .6s;
}

@media screen and (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  z-index: 10;
  color: #fff;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  position: absolute;
  bottom: 20px;
  left: 15%;
  right: 15%;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  vertical-align: text-bottom;
  border: .25em solid;
  border-right-color: #0000;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  animation: .75s linear infinite spinner-border;
  display: inline-block;
}

.spinner-border-sm {
  border-width: .2em;
  width: 1rem;
  height: 1rem;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
  }
}

.spinner-grow {
  vertical-align: text-bottom;
  opacity: 0;
  background-color: currentColor;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  animation: .75s linear infinite spinner-grow;
  display: inline-block;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:focus, a.bg-primary:hover, button.bg-primary:focus, button.bg-primary:hover {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:focus, a.bg-secondary:hover, button.bg-secondary:focus, button.bg-secondary:hover {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:focus, a.bg-success:hover, button.bg-success:focus, button.bg-success:hover {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:focus, a.bg-info:hover, button.bg-info:focus, button.bg-info:hover {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:focus, a.bg-warning:hover, button.bg-warning:focus, button.bg-warning:hover {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:focus, a.bg-danger:hover, button.bg-danger:focus, button.bg-danger:hover {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:focus, a.bg-light:hover, button.bg-light:focus, button.bg-light:hover {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:focus, a.bg-dark:hover, button.bg-dark:focus, button.bg-dark:hover {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: #0000 !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded {
  border-radius: .25rem !important;
}

.rounded-top {
  border-top-left-radius: .25rem !important;
  border-top-right-radius: .25rem !important;
}

.rounded-right {
  border-top-right-radius: .25rem !important;
  border-bottom-right-radius: .25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important;
}

.rounded-left {
  border-top-left-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (width >= 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (width >= 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (width >= 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (width >= 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  width: 100%;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.embed-responsive:before {
  content: "";
  display: block;
}

.embed-responsive .embed-responsive-item, .embed-responsive embed, .embed-responsive iframe, .embed-responsive object, .embed-responsive video {
  border: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.embed-responsive-21by9:before {
  padding-top: 42.8571%;
}

.embed-responsive-16by9:before {
  padding-top: 56.25%;
}

.embed-responsive-3by4:before {
  padding-top: 133.333%;
}

.embed-responsive-1by1:before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (width >= 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (width >= 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (width >= 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (width >= 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (width >= 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}

@media (width >= 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}

@media (width >= 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}

@media (width >= 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

@supports (position: -webkit-sticky) or (position: sticky) {
  .sticky-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
  width: 1px;
  height: 1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  white-space: normal;
  width: auto;
  height: auto;
  position: static;
  overflow: visible;
}

.shadow-sm {
  box-shadow: 0 .125rem .25rem #00000013 !important;
}

.shadow {
  box-shadow: 0 .5rem 1rem #00000026 !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem #0000002d !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0, .my-0 {
  margin-top: 0 !important;
}

.mr-0, .mx-0 {
  margin-right: 0 !important;
}

.mb-0, .my-0 {
  margin-bottom: 0 !important;
}

.ml-0, .mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: .25rem !important;
}

.mt-1, .my-1 {
  margin-top: .25rem !important;
}

.mr-1, .mx-1 {
  margin-right: .25rem !important;
}

.mb-1, .my-1 {
  margin-bottom: .25rem !important;
}

.ml-1, .mx-1 {
  margin-left: .25rem !important;
}

.m-2 {
  margin: .5rem !important;
}

.mt-2, .my-2 {
  margin-top: .5rem !important;
}

.mr-2, .mx-2 {
  margin-right: .5rem !important;
}

.mb-2, .my-2 {
  margin-bottom: .5rem !important;
}

.ml-2, .mx-2 {
  margin-left: .5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3, .my-3 {
  margin-top: 1rem !important;
}

.mr-3, .mx-3 {
  margin-right: 1rem !important;
}

.mb-3, .my-3 {
  margin-bottom: 1rem !important;
}

.ml-3, .mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4, .my-4 {
  margin-top: 1.5rem !important;
}

.mr-4, .mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4, .my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4, .mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5, .my-5 {
  margin-top: 3rem !important;
}

.mr-5, .mx-5 {
  margin-right: 3rem !important;
}

.mb-5, .my-5 {
  margin-bottom: 3rem !important;
}

.ml-5, .mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0, .py-0 {
  padding-top: 0 !important;
}

.pr-0, .px-0 {
  padding-right: 0 !important;
}

.pb-0, .py-0 {
  padding-bottom: 0 !important;
}

.pl-0, .px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: .25rem !important;
}

.pt-1, .py-1 {
  padding-top: .25rem !important;
}

.pr-1, .px-1 {
  padding-right: .25rem !important;
}

.pb-1, .py-1 {
  padding-bottom: .25rem !important;
}

.pl-1, .px-1 {
  padding-left: .25rem !important;
}

.p-2 {
  padding: .5rem !important;
}

.pt-2, .py-2 {
  padding-top: .5rem !important;
}

.pr-2, .px-2 {
  padding-right: .5rem !important;
}

.pb-2, .py-2 {
  padding-bottom: .5rem !important;
}

.pl-2, .px-2 {
  padding-left: .5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3, .py-3 {
  padding-top: 1rem !important;
}

.pr-3, .px-3 {
  padding-right: 1rem !important;
}

.pb-3, .py-3 {
  padding-bottom: 1rem !important;
}

.pl-3, .px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4, .py-4 {
  padding-top: 1.5rem !important;
}

.pr-4, .px-4 {
  padding-right: 1.5rem !important;
}

.pb-4, .py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4, .px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5, .py-5 {
  padding-top: 3rem !important;
}

.pr-5, .px-5 {
  padding-right: 3rem !important;
}

.pb-5, .py-5 {
  padding-bottom: 3rem !important;
}

.pl-5, .px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -.25rem !important;
}

.mt-n1, .my-n1 {
  margin-top: -.25rem !important;
}

.mr-n1, .mx-n1 {
  margin-right: -.25rem !important;
}

.mb-n1, .my-n1 {
  margin-bottom: -.25rem !important;
}

.ml-n1, .mx-n1 {
  margin-left: -.25rem !important;
}

.m-n2 {
  margin: -.5rem !important;
}

.mt-n2, .my-n2 {
  margin-top: -.5rem !important;
}

.mr-n2, .mx-n2 {
  margin-right: -.5rem !important;
}

.mb-n2, .my-n2 {
  margin-bottom: -.5rem !important;
}

.ml-n2, .mx-n2 {
  margin-left: -.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3, .my-n3 {
  margin-top: -1rem !important;
}

.mr-n3, .mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3, .my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3, .mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4, .my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4, .mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4, .my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4, .mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5, .my-n5 {
  margin-top: -3rem !important;
}

.mr-n5, .mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5, .my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5, .mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto, .my-auto {
  margin-top: auto !important;
}

.mr-auto, .mx-auto {
  margin-right: auto !important;
}

.mb-auto, .my-auto {
  margin-bottom: auto !important;
}

.ml-auto, .mx-auto {
  margin-left: auto !important;
}

@media (width >= 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0, .my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0, .mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0, .my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0, .mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: .25rem !important;
  }

  .mt-sm-1, .my-sm-1 {
    margin-top: .25rem !important;
  }

  .mr-sm-1, .mx-sm-1 {
    margin-right: .25rem !important;
  }

  .mb-sm-1, .my-sm-1 {
    margin-bottom: .25rem !important;
  }

  .ml-sm-1, .mx-sm-1 {
    margin-left: .25rem !important;
  }

  .m-sm-2 {
    margin: .5rem !important;
  }

  .mt-sm-2, .my-sm-2 {
    margin-top: .5rem !important;
  }

  .mr-sm-2, .mx-sm-2 {
    margin-right: .5rem !important;
  }

  .mb-sm-2, .my-sm-2 {
    margin-bottom: .5rem !important;
  }

  .ml-sm-2, .mx-sm-2 {
    margin-left: .5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3, .my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3, .mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3, .my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3, .mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4, .my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4, .mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4, .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4, .mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5, .my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5, .mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5, .my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5, .mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0, .py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0, .px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0, .py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0, .px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: .25rem !important;
  }

  .pt-sm-1, .py-sm-1 {
    padding-top: .25rem !important;
  }

  .pr-sm-1, .px-sm-1 {
    padding-right: .25rem !important;
  }

  .pb-sm-1, .py-sm-1 {
    padding-bottom: .25rem !important;
  }

  .pl-sm-1, .px-sm-1 {
    padding-left: .25rem !important;
  }

  .p-sm-2 {
    padding: .5rem !important;
  }

  .pt-sm-2, .py-sm-2 {
    padding-top: .5rem !important;
  }

  .pr-sm-2, .px-sm-2 {
    padding-right: .5rem !important;
  }

  .pb-sm-2, .py-sm-2 {
    padding-bottom: .5rem !important;
  }

  .pl-sm-2, .px-sm-2 {
    padding-left: .5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3, .py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3, .px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3, .py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3, .px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4, .py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4, .px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4, .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4, .px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5, .py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5, .px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5, .py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5, .px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -.25rem !important;
  }

  .mt-sm-n1, .my-sm-n1 {
    margin-top: -.25rem !important;
  }

  .mr-sm-n1, .mx-sm-n1 {
    margin-right: -.25rem !important;
  }

  .mb-sm-n1, .my-sm-n1 {
    margin-bottom: -.25rem !important;
  }

  .ml-sm-n1, .mx-sm-n1 {
    margin-left: -.25rem !important;
  }

  .m-sm-n2 {
    margin: -.5rem !important;
  }

  .mt-sm-n2, .my-sm-n2 {
    margin-top: -.5rem !important;
  }

  .mr-sm-n2, .mx-sm-n2 {
    margin-right: -.5rem !important;
  }

  .mb-sm-n2, .my-sm-n2 {
    margin-bottom: -.5rem !important;
  }

  .ml-sm-n2, .mx-sm-n2 {
    margin-left: -.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3, .my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3, .mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3, .my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3, .mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4, .my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4, .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4, .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4, .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5, .my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5, .mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5, .my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5, .mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto, .my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto, .mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto, .my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto, .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (width >= 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0, .my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0, .mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0, .my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0, .mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: .25rem !important;
  }

  .mt-md-1, .my-md-1 {
    margin-top: .25rem !important;
  }

  .mr-md-1, .mx-md-1 {
    margin-right: .25rem !important;
  }

  .mb-md-1, .my-md-1 {
    margin-bottom: .25rem !important;
  }

  .ml-md-1, .mx-md-1 {
    margin-left: .25rem !important;
  }

  .m-md-2 {
    margin: .5rem !important;
  }

  .mt-md-2, .my-md-2 {
    margin-top: .5rem !important;
  }

  .mr-md-2, .mx-md-2 {
    margin-right: .5rem !important;
  }

  .mb-md-2, .my-md-2 {
    margin-bottom: .5rem !important;
  }

  .ml-md-2, .mx-md-2 {
    margin-left: .5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3, .my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3, .mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3, .my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3, .mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4, .my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4, .mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4, .my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4, .mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5, .my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5, .mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5, .my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5, .mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0, .py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0, .px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0, .py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0, .px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: .25rem !important;
  }

  .pt-md-1, .py-md-1 {
    padding-top: .25rem !important;
  }

  .pr-md-1, .px-md-1 {
    padding-right: .25rem !important;
  }

  .pb-md-1, .py-md-1 {
    padding-bottom: .25rem !important;
  }

  .pl-md-1, .px-md-1 {
    padding-left: .25rem !important;
  }

  .p-md-2 {
    padding: .5rem !important;
  }

  .pt-md-2, .py-md-2 {
    padding-top: .5rem !important;
  }

  .pr-md-2, .px-md-2 {
    padding-right: .5rem !important;
  }

  .pb-md-2, .py-md-2 {
    padding-bottom: .5rem !important;
  }

  .pl-md-2, .px-md-2 {
    padding-left: .5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3, .py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3, .px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3, .py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3, .px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4, .py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4, .px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4, .py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4, .px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5, .py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5, .px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5, .py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5, .px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -.25rem !important;
  }

  .mt-md-n1, .my-md-n1 {
    margin-top: -.25rem !important;
  }

  .mr-md-n1, .mx-md-n1 {
    margin-right: -.25rem !important;
  }

  .mb-md-n1, .my-md-n1 {
    margin-bottom: -.25rem !important;
  }

  .ml-md-n1, .mx-md-n1 {
    margin-left: -.25rem !important;
  }

  .m-md-n2 {
    margin: -.5rem !important;
  }

  .mt-md-n2, .my-md-n2 {
    margin-top: -.5rem !important;
  }

  .mr-md-n2, .mx-md-n2 {
    margin-right: -.5rem !important;
  }

  .mb-md-n2, .my-md-n2 {
    margin-bottom: -.5rem !important;
  }

  .ml-md-n2, .mx-md-n2 {
    margin-left: -.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3, .my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3, .mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3, .my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3, .mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4, .my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4, .mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4, .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4, .mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5, .my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5, .mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5, .my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5, .mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto, .my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto, .mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto, .my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto, .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (width >= 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0, .my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0, .mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0, .my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0, .mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: .25rem !important;
  }

  .mt-lg-1, .my-lg-1 {
    margin-top: .25rem !important;
  }

  .mr-lg-1, .mx-lg-1 {
    margin-right: .25rem !important;
  }

  .mb-lg-1, .my-lg-1 {
    margin-bottom: .25rem !important;
  }

  .ml-lg-1, .mx-lg-1 {
    margin-left: .25rem !important;
  }

  .m-lg-2 {
    margin: .5rem !important;
  }

  .mt-lg-2, .my-lg-2 {
    margin-top: .5rem !important;
  }

  .mr-lg-2, .mx-lg-2 {
    margin-right: .5rem !important;
  }

  .mb-lg-2, .my-lg-2 {
    margin-bottom: .5rem !important;
  }

  .ml-lg-2, .mx-lg-2 {
    margin-left: .5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3, .my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3, .mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3, .my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3, .mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4, .my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4, .mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4, .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4, .mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5, .my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5, .mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5, .my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5, .mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0, .py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0, .px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0, .py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0, .px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: .25rem !important;
  }

  .pt-lg-1, .py-lg-1 {
    padding-top: .25rem !important;
  }

  .pr-lg-1, .px-lg-1 {
    padding-right: .25rem !important;
  }

  .pb-lg-1, .py-lg-1 {
    padding-bottom: .25rem !important;
  }

  .pl-lg-1, .px-lg-1 {
    padding-left: .25rem !important;
  }

  .p-lg-2 {
    padding: .5rem !important;
  }

  .pt-lg-2, .py-lg-2 {
    padding-top: .5rem !important;
  }

  .pr-lg-2, .px-lg-2 {
    padding-right: .5rem !important;
  }

  .pb-lg-2, .py-lg-2 {
    padding-bottom: .5rem !important;
  }

  .pl-lg-2, .px-lg-2 {
    padding-left: .5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3, .py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3, .px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3, .py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3, .px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4, .py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4, .px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4, .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4, .px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5, .py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5, .px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5, .py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5, .px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -.25rem !important;
  }

  .mt-lg-n1, .my-lg-n1 {
    margin-top: -.25rem !important;
  }

  .mr-lg-n1, .mx-lg-n1 {
    margin-right: -.25rem !important;
  }

  .mb-lg-n1, .my-lg-n1 {
    margin-bottom: -.25rem !important;
  }

  .ml-lg-n1, .mx-lg-n1 {
    margin-left: -.25rem !important;
  }

  .m-lg-n2 {
    margin: -.5rem !important;
  }

  .mt-lg-n2, .my-lg-n2 {
    margin-top: -.5rem !important;
  }

  .mr-lg-n2, .mx-lg-n2 {
    margin-right: -.5rem !important;
  }

  .mb-lg-n2, .my-lg-n2 {
    margin-bottom: -.5rem !important;
  }

  .ml-lg-n2, .mx-lg-n2 {
    margin-left: -.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3, .my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3, .mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3, .my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3, .mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4, .my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4, .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4, .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4, .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5, .my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5, .mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5, .my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5, .mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto, .my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto, .mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto, .my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto, .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (width >= 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0, .my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0, .mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0, .my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0, .mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: .25rem !important;
  }

  .mt-xl-1, .my-xl-1 {
    margin-top: .25rem !important;
  }

  .mr-xl-1, .mx-xl-1 {
    margin-right: .25rem !important;
  }

  .mb-xl-1, .my-xl-1 {
    margin-bottom: .25rem !important;
  }

  .ml-xl-1, .mx-xl-1 {
    margin-left: .25rem !important;
  }

  .m-xl-2 {
    margin: .5rem !important;
  }

  .mt-xl-2, .my-xl-2 {
    margin-top: .5rem !important;
  }

  .mr-xl-2, .mx-xl-2 {
    margin-right: .5rem !important;
  }

  .mb-xl-2, .my-xl-2 {
    margin-bottom: .5rem !important;
  }

  .ml-xl-2, .mx-xl-2 {
    margin-left: .5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3, .my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3, .mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3, .my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3, .mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4, .my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4, .mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4, .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4, .mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5, .my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5, .mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5, .my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5, .mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0, .py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0, .px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0, .py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0, .px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: .25rem !important;
  }

  .pt-xl-1, .py-xl-1 {
    padding-top: .25rem !important;
  }

  .pr-xl-1, .px-xl-1 {
    padding-right: .25rem !important;
  }

  .pb-xl-1, .py-xl-1 {
    padding-bottom: .25rem !important;
  }

  .pl-xl-1, .px-xl-1 {
    padding-left: .25rem !important;
  }

  .p-xl-2 {
    padding: .5rem !important;
  }

  .pt-xl-2, .py-xl-2 {
    padding-top: .5rem !important;
  }

  .pr-xl-2, .px-xl-2 {
    padding-right: .5rem !important;
  }

  .pb-xl-2, .py-xl-2 {
    padding-bottom: .5rem !important;
  }

  .pl-xl-2, .px-xl-2 {
    padding-left: .5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3, .py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3, .px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3, .py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3, .px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4, .py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4, .px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4, .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4, .px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5, .py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5, .px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5, .py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5, .px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -.25rem !important;
  }

  .mt-xl-n1, .my-xl-n1 {
    margin-top: -.25rem !important;
  }

  .mr-xl-n1, .mx-xl-n1 {
    margin-right: -.25rem !important;
  }

  .mb-xl-n1, .my-xl-n1 {
    margin-bottom: -.25rem !important;
  }

  .ml-xl-n1, .mx-xl-n1 {
    margin-left: -.25rem !important;
  }

  .m-xl-n2 {
    margin: -.5rem !important;
  }

  .mt-xl-n2, .my-xl-n2 {
    margin-top: -.5rem !important;
  }

  .mr-xl-n2, .mx-xl-n2 {
    margin-right: -.5rem !important;
  }

  .mb-xl-n2, .my-xl-n2 {
    margin-bottom: -.5rem !important;
  }

  .ml-xl-n2, .mx-xl-n2 {
    margin-left: -.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3, .my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3, .mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3, .my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3, .mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4, .my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4, .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4, .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4, .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5, .my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5, .mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5, .my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5, .mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto, .my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto, .mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto, .my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto, .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (width >= 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (width >= 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (width >= 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (width >= 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:focus, a.text-primary:hover {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:focus, a.text-secondary:hover {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:focus, a.text-success:hover {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:focus, a.text-info:hover {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:focus, a.text-warning:hover {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:focus, a.text-danger:hover {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:focus, a.text-light:hover {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:focus, a.text-dark:hover {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: #00000080 !important;
}

.text-white-50 {
  color: #ffffff80 !important;
}

.text-hide {
  font: 0 / 0 a;
  color: #0000;
  text-shadow: none;
  background-color: #0000;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *, :after, :before {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  blockquote, pre {
    page-break-inside: avoid;
    border: 1px solid #adb5bd;
  }

  thead {
    display: table-header-group;
  }

  img, tr {
    page-break-inside: avoid;
  }

  h2, h3, p {
    orphans: 3;
    widows: 3;
  }

  h2, h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }

  body, .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }

  .table td, .table th {
    background-color: #fff !important;
  }

  .table-bordered td, .table-bordered th {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }

  .table-dark tbody + tbody, .table-dark td, .table-dark th, .table-dark thead th {
    border-color: #dee2e6;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

.ls-base-page {
  text-align: center;
  flex-direction: column;
  grid-area: 1 / 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  position: relative;
  overflow: hidden;
}

.ls-back-pressed {
  text-align: center;
  flex-direction: column;
  grid-area: 1 / 1;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media (width <= 575px) {
  .ls-base-page {
    padding: 0 .5rem;
  }
}

.ls-flex-start {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.ls-step-cont {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: .5rem;
  margin-bottom: .5rem;
  display: flex;
  position: relative;
}

@media (width >= 576px) {
  .ls-base-page {
    max-width: 540px;
  }
}

@media (width >= 768px) {
  .ls-base-page {
    max-width: 720px;
  }
}

@media (width >= 992px) {
  .ls-base-page {
    max-width: 960px;
  }
}

@media (width >= 1200px) {
  .ls-base-page {
    max-width: 1140px;
  }
}

.ls-instruction-cont {
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  display: flex;
}

.ls-instruction-cont.col {
  flex-direction: column;
  align-items: center;
  overflow: hidden auto;
}

.ls-instruction-text {
  white-space: pre-wrap;
  flex-grow: 1;
  width: 0;
  font-family: Source Sans Pro, sans-serif;
  font-size: 1.5rem;
}

.ls-instruction-cont.col > .ls-instruction-text {
  width: 100%;
}

.ls-instruction-image {
  opacity: 0;
  margin: 1rem;
}

.ls-instruction-image.view {
  opacity: 1;
}

.ls-instruction-image.max-width {
  opacity: 1;
  max-width: 100%;
  margin-top: 2rem;
}

.ls-instruction-image.max-height {
  opacity: 1;
  flex-grow: 1;
  margin-top: 2rem;
}

@media (width <= 812px), (height <= 420px) {
  .ls-instruction-cont {
    padding-top: 10px;
  }
}

@media (width <= 575px) {
  .ls-instruction-defimage {
    display: none;
  }
}

.ls-prompt {
  z-index: 1000;
  background: #fff;
  border: 1px solid #000;
  border-radius: 1rem;
  padding: 2rem;
  font-size: 1.5rem;
  font-weight: bold;
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
}

.ls-shelf-cont {
  position: absolute;
}

.ls-shelf-hidden {
  visibility: hidden;
  object-fit: scale-down;
  position: relative;
  top: auto;
  left: auto;
  transform: none;
  max-height: 100% !important;
}

.ls-shelf-img.max-width {
  width: 100%;
}

.ls-shelf-img.max-height {
  height: 100%;
}

.ls-scroll-stim {
  width: 100%;
}

.ls-zoom-lens {
  visibility: hidden;
  border: 1px solid #d4d4d4;
  position: absolute;
}

.ls-zoom-src {
  z-index: 4;
  position: absolute;
  max-width: 100% !important;
  max-height: 100% !important;
}

.ls-zoom-result {
  visibility: hidden;
  z-index: 3;
  border: 1px solid #d4d4d4;
  position: absolute;
  overflow: hidden;
  max-width: 100% !important;
  max-height: 100% !important;
}

.ls-zoom-result-img {
  position: absolute;
}

.ls-social-cont {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.ls-social-overlay {
  z-index: 1000000;
  background-color: #fff;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}

.ls-video {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% - 1rem);
  display: flex;
  position: relative;
}

.ls-video-fullscreen {
  z-index: 1000;
  background-color: #000;
  width: 100vw;
  height: 100dvh;
  position: fixed;
  top: 0;
  left: 0;
}

.ls-video-cont {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.ls-video-player {
  max-width: 100%;
  max-height: 100%;
}

.ls-video-playbtn {
  color: #000;
  cursor: pointer;
  box-sizing: border-box;
  z-index: 100;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 1rem;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 50px;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ls-video-controls {
  box-sizing: border-box;
  z-index: 1000;
  background-color: #fff;
  align-items: center;
  width: 90%;
  height: 30px;
  padding: 0 5px;
  display: flex;
  position: absolute;
  bottom: 5%;
  left: 5%;
}

.ls-video-playmini {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  font-weight: bold;
  display: flex;
}

.ls-video-prog {
  flex-grow: 1;
  height: 7px;
  position: relative;
}

.ls-video-prog > div:first-of-type {
  z-index: 100;
  background-color: #000;
  height: 7px;
  position: absolute;
}

.ls-video-prog > div:nth-of-type(2) {
  background-color: #dcdcdc;
  width: 100%;
  height: 7px;
  position: absolute;
}

.ls-video-vol {
  cursor: pointer;
  width: 24px;
  height: 24px;
  margin-left: 10px;
  position: relative;
}

.ls-video-vol-control {
  box-sizing: border-box;
  background-color: #fff;
  align-items: flex-end;
  width: 21px;
  height: 100px;
  padding: 7px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(4px, -101%);
}

.ls-video-vol-control > div:first-of-type {
  z-index: 100;
  background-color: #000;
  width: 7px;
  position: absolute;
  left: 7px;
}

.ls-video-vol-control > div:nth-of-type(2) {
  background-color: #dcdcdc;
  width: 7px;
  height: calc(100% - 14px);
  position: absolute;
  left: 7px;
}

.ls-video-vol-control > div:nth-of-type(3) {
  z-index: 110;
  background-color: #000;
  border-radius: 11px;
  width: 12px;
  height: 12px;
  position: absolute;
  left: 7px;
  transform: translate(-2.5px, 2px);
}

.ls-video-player::-webkit-media-controls {
  display: none;
}

.ls-video-player::-webkit-media-controls-enclosure {
  display: none !important;
}

.ls-bespoke-step {
  z-index: 100000;
  width: 100vw;
  height: 100dvh;
  position: fixed;
  top: 0;
}

.ls-bespoke-button-cont {
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 20%;
  display: flex;
  position: absolute;
}

.ls-bespoke-button-cont.ls-bespoke-button-bottom {
  bottom: 0;
}

.ls-bespoke-button-cont.ls-bespoke-button-middle {
  top: 50%;
  transform: translate(0, -50%);
}

.ls-bespoke-button-cont.ls-bespoke-button-top {
  top: 0;
}

.ls-bespoke-button {
  cursor: pointer;
  border-radius: 6px;
  min-width: 80%;
  padding: 8px;
  font-weight: bold;
}

.ls-bespoke-video {
  width: 100%;
  height: 100%;
}

.slick-slider {
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  display: block;
  position: relative;
}

.slick-list {
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track, .slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
  top: 0;
  left: 0;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  border: 1px solid #0000;
  height: auto;
  display: block;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-loading .slick-list {
  background: #fff url("ajax-loader.32c19c5c.gif") center no-repeat;
}

@font-face {
  font-family: slick;
  font-weight: normal;
  font-style: normal;
  src: url("slick.019ff462.eot");
  src: url("slick.019ff462.eot#iefix") format("embedded-opentype"), url("slick.c8d05265.woff") format("woff"), url("slick.441ccdd6.ttf") format("truetype"), url("slick.0ba9c062.svg#slick") format("svg");
}

.slick-prev, .slick-next {
  cursor: pointer;
  color: #0000;
  background: none;
  border: none;
  outline: none;
  width: 20px;
  height: 20px;
  padding: 0;
  font-size: 0;
  line-height: 0;
  display: block;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
  color: #0000;
  background: none;
  outline: none;
}

.slick-prev:hover:before, .slick-prev:focus:before, .slick-next:hover:before, .slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before {
  opacity: .25;
}

.slick-prev:before, .slick-next:before {
  opacity: .75;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: slick;
  font-size: 20px;
  line-height: 1;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
  position: absolute;
  bottom: -25px;
}

.slick-dots li {
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  display: inline-block;
  position: relative;
}

.slick-dots li button {
  cursor: pointer;
  color: #0000;
  background: none;
  border: 0;
  outline: none;
  width: 20px;
  height: 20px;
  padding: 5px;
  font-size: 0;
  line-height: 0;
  display: block;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  content: "•";
  text-align: center;
  opacity: .25;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 20px;
  height: 20px;
  font-family: slick;
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.slick-dots li.slick-active button:before {
  opacity: .75;
  color: #000;
}

* {
  text-rendering: optimizeLegibility;
}

html {
  height: calc(var(--vh, 1vh) * 100);
  max-width: 100vw;
  padding-bottom: 70px;
}

body, #root, .ls {
  height: inherit;
  color: var(--ui-text-color);
  background-color: var(--primary-color);
  flex-direction: column;
  max-width: 100%;
  display: flex;
}

.ls-body {
  flex-grow: 1;
  width: 100%;
  height: 0;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.ls-body::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.ls-content {
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  position: relative;
  overflow: scroll;
}

.ls-content::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.ls.debug, .ls.demographics, .ls.index {
  overflow: auto;
}

.ls-button {
  text-transform: uppercase;
  border: 1px solid var(--ui-secondary-color);
  vertical-align: middle;
  cursor: pointer;
  border-radius: .25rem;
  margin: 2rem 0;
  padding: .375rem .75rem;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.5;
  position: relative;
}

.lang-ar {
  text-align: right;
}

body {
  font-family: Source Sans Pro, sans-serif;
}

.ls-social-cont img {
  max-height: unset !important;
  max-width: unset !important;
}

.studyCont img:not(.ls-instruction-image):not(.ls-shelf-hidden):not(.ls-zoom-result-img):not(.retry-orientation-imgtag) {
  max-width: 100% !important;
  max-height: calc(100% - 20px) !important;
}

#dark2 img {
  transform: none;
}

a, a:not([href]):not([tabindex]), a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover, .link {
  color: var(--ui-text-color);
  cursor: pointer;
  background-color: #0000;
  text-decoration: none;
}

a:hover, a:not([href]):not([tabindex]):hover, .link:hover {
  cursor: pointer;
}

p, a {
  font-size: 1.2rem;
}

button, .btn, .btn-primary {
  background-color: var(--ui-primary-color);
  border-color: var(--ui-secondary-color);
  color: var(--ui-text-color);
}

button:hover, .btn:hover, .btn-primary:hover, .btn-primary:active {
  background-color: var(--ui-secondary-color);
  border-color: var(--ui-secondary-color);
  color: #fff;
}

textarea, input {
  border: 1px solid var(--ui-primary-color);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #8e8e8e;
  border-color: #8e8e8e;
}

.ls-footer .btn-primary.disabled, .ls-footer .btn-primary:disabled {
  display: none;
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.button-primary {
  color: var(--ui-text-color);
  text-transform: uppercase;
  margin-top: 4px;
  font-size: 2rem;
  font-weight: 600;
  position: relative;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
  background-color: var(--ui-secondary-color);
  border-color: var(--ui-secondary-color);
}

.button-secondary {
  color: var(--ui-text-color);
  text-transform: uppercase;
  margin-top: 4px;
  font-size: 1.6rem;
  font-weight: 600;
  position: relative;
}

.start-button {
  background-color: #26b13f;
  border-color: #26b13f;
}

.start-button:hover, .start-button:active, .start-button:focus {
  background-color: #187229;
  border-color: #187229;
}

.invalidate-button {
  background-color: #b12653;
  border-color: #b12653;
}

.invalidate-button:hover, .invalidate-button:active, .invalidate-button:focus {
  background-color: #721835;
  border-color: #721835;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
}

.progress {
  border-radius: 0;
  height: 4px;
}

.progress-bar {
  background-color: var(--ui-primary-color);
}

.block {
  display: block;
}

.absoluteCenter {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.centerContent {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  display: flex;
}

.centerAlign {
  text-align: center;
}

.rightAlign {
  text-align: right;
}

.floatRight {
  float: right;
}

.logo {
  width: 100%;
  min-width: 150px;
  max-width: 200px;
}

.ls-header {
  background: var(--primary-color);
  z-index: 2;
  border-bottom: 1px solid var(--ui-secondary-color);
  text-align: left;
  height: 70px;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
}

.ls-footer-cont, .ls-header-cont {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.ls-footer-mob {
  display: none;
}

.ls-footer-preview, .ls-footer-index, .ls-footer-study {
  justify-content: flex-end;
  display: flex;
}

.ls-footer .ls-button {
  margin: auto 0;
}

.menu {
  color: var(--compliment-color);
  margin: 14px 0;
  font-size: 2.5rem;
}

.menu:hover {
  cursor: pointer;
}

.menu-cont {
  position: absolute;
}

.ls-header .menu-cont button {
  float: left;
  clear: both;
  text-transform: uppercase;
  margin-bottom: 10px;
  box-shadow: 0 3px 6px #00000029, 0 3px 6px #0000003b;
}

.ls-footer {
  background: var(--primary-color);
  border-top: 1px solid var(--ui-secondary-color);
  text-align: left;
  width: 100%;
  height: 70px;
  overflow: hidden;
}

.ls-footer .menu-cont {
  transform: translateY(-100%)translateY(-70px);
}

.ls-footer .menu-cont button {
  float: left;
  clear: both;
  text-transform: uppercase;
  margin-bottom: 10px;
  box-shadow: 0 3px 6px #00000029, 0 3px 6px #0000003b;
}

.ls-main {
  text-align: center;
  position: relative;
}

.help-cont {
  cursor: pointer;
  width: 100%;
  height: 100%;
  max-height: 68px;
  position: relative;
  top: 2px;
}

.obscure {
  background: var(--primary-color);
  width: 100%;
  height: 100%;
  max-height: 70px;
  position: relative;
  top: -2px;
}

.help-tag {
  color: var(--ui-text-color);
  text-transform: uppercase;
  text-align: center;
  background: var(--ui-primary-color);
  z-index: -1;
  border-radius: 0 0 5px 5px;
  width: 50%;
  margin-top: -42px;
  padding: 3px;
  font-size: 2rem;
  font-weight: 600;
  transition: all .3s ease-in-out;
  position: absolute;
  right: 10px;
  box-shadow: 0 3px 6px #00000029, 0 3px 6px #0000003b;
}

.help-tag.help-tag-active {
  margin-top: -2px;
}

.ls-modal {
  z-index: 2;
  border: 5px solid var(--ui-secondary-color);
  background: #fff;
  flex-direction: column;
  height: 90vh;
  padding: 50px;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.ls-modalbg {
  opacity: .5;
  filter: blur(50px);
  background-color: #fff;
  width: 100%;
  height: 100%;
}

.ls-modalclose {
  border: 5px solid var(--ui-primary-color);
  cursor: pointer;
  background: #fff;
  border-radius: 50px;
  padding: 10px 14px;
  font-size: 30px;
  position: absolute;
  top: -30px;
  right: -32px;
}

.ls-modalContentWrap {
  max-height: 616px;
  overflow: hidden auto;
}

.sliderImg {
  max-width: 300px;
  margin: 20px 0;
  display: inline-block !important;
}

.slick-slide {
  text-align: center;
}

.slick-next, .slick-prev {
  z-index: 2;
  width: 50px;
  height: 50px;
  top: 30%;
}

.slick-prev {
  left: 0;
}

.slick-next {
  right: 0;
}

.slick-prev:before, .slick-next:before {
  color: gray;
  font-size: 50px;
}

.slick-prev:before {
  content: "←";
}

.slick-next:before {
  content: "→";
}

.slick-dots li button:before {
  content: "•";
}

.finalInstructionSlick, .instructionsText {
  margin-top: 50px;
}

.setupCont, .studyCont, .studyCont > .row, .studyCont > .row > .col-12, .studyCont .ls-main {
  height: 100%;
}

.studyCont img:not(.ls-instruction-image):not(.ls-shelf-hidden):not(.ls-zoom-result-img):not(.ls-scroll-stim):not(.retry-orientation-imgtag) {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.studyCont #calibrationElement #arrimg {
  position: fixed;
  top: calc(50% - 80px);
  left: 50%;
}

.ls-lumenShelfAoi {
  background: #0000001a;
}

.ls-lumenShelfAoi:hover, .ls-lumenShelfAoi:focus {
  background: #0000004d;
}

.studyCont pre {
  white-space: pre-wrap;
  font-family: Source Sans Pro, sans-serif;
  font-size: 1.5rem;
  overflow: hidden;
}

.studyCont pre b {
  font-weight: 900;
}

.lumenIframeStandard {
  visibility: hidden;
  z-index: 3;
  height: calc(var(--vh, 1vh) * 100);
  border: none;
  width: 100vw;
  display: inline;
  position: fixed;
  transform: translate(-50%);
}

.lumenIframePostDemographics {
  visibility: hidden;
  z-index: 3;
  height: calc(var(--vh, 1vh) * 100);
  border: none;
  width: 100vw;
  display: inline;
  position: fixed;
}

.lumenIframeDemographics {
  visibility: hidden;
  z-index: 2;
  height: calc(var(--vh, 1vh) * 100);
  border: none;
  width: 100%;
  display: inline;
  position: absolute;
  transform: none;
}

#headPositionElement {
  background: #00000080;
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#lr_show_video {
  max-width: 50%;
  max-height: 50%;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%)rotateY(180deg) !important;
}

#calibrationElement, #validationElement {
  z-index: 2;
  background: #00000080;
  width: 100%;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100% !important;
}

#loader_calib {
  position: absolute;
}

.toggle-fade-enter {
  opacity: .01;
}

.toggle-fade-enter.toggle-fade-enter-active {
  opacity: 1;
  transition: opacity .3s ease-in;
}

.toggle-fade-leave {
  opacity: 1;
}

.toggle-fade-leave.toggle-fade-leave-active {
  opacity: .01;
  transition: opacity .3s ease-in;
}

.questionRow {
  text-align: left;
  height: 100%;
  overflow: auto;
}

.questionRow form {
  width: 100%;
  margin-bottom: 20px;
}

.questionRow form div {
  text-align: left;
}

.questionRow input:not([type="text"]) {
  height: 15px;
  margin-right: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-66%);
}

.questionRow textarea, .questionRow input[type="text"] {
  border: 1px solid var(--compliment-color);
  width: 100%;
  max-width: 400px;
  margin-top: 10px;
  padding: 1px 2px;
  font-size: 16px !important;
}

.questionRow textarea {
  min-height: 100px;
}

.questionRow .radioLabel {
  width: calc(100% - 23px);
  margin-left: 24px;
  font-size: 1.05rem;
}

.questionRow .radioAnswerCont, .questionRow .checkboxAnswerCont {
  margin-bottom: 10px;
  position: relative;
}

.questionRow .questionError {
  color: #dc3545;
}

.questionCont {
  transition: visibility, opacity .5s linear;
}

.questionCont:not(.active) {
  visibility: hidden;
  opacity: 0;
  height: 0;
  position: fixed;
}

.btn-question {
  margin-bottom: 20px;
}

.retry-instructions {
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

.retry-mobile-fullscreen {
  width: 100vw;
  height: 150vh;
  font-weight: 500;
  overflow-y: scroll;
}

.retry-mobile-fullscreen-escape {
  cursor: pointer;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  width: 100vw;
  padding: 8px;
  font-size: 1rem;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.retry-mobile-fullscreen-text {
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  padding: 8px;
  display: flex;
  top: 0;
  left: 0;
}

.retry-mobile-fullscreen-thankyou {
  background-color: #fff;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.fullscreen-test {
  z-index: 10000;
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 1.5rem;
  font-weight: 500;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.fullscreen-test > div.fullscreen-test-instructions {
  background-color: #fff;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.fullscreen-test-instructions {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 4rem;
  display: flex;
}

.zoom-test-box {
  border: 1px solid #000;
  border-radius: 8px;
  width: 135px;
  padding: 2rem;
  position: absolute;
}

.zoom-test-drop {
  border: 1px solid #000;
  border-radius: 8px;
  width: 10vw;
  height: 10vh;
  position: absolute;
  bottom: 0;
  right: 0;
}

.retry-orientation {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  padding: 2rem;
  display: flex;
}

.studyCont .retry-orientation {
  position: fixed;
  top: 0;
  left: 0;
}

.retry-orientation-text {
  text-align: center;
}

.retry-orientation-rotate {
  padding: 2rem;
  font-size: 4rem;
}

.retry-orientation-img {
  max-width: 60vw;
  max-height: 60vh;
  margin-top: 5rem;
}

.retry-orientation-img > img {
  width: 100%;
  height: 100%;
}

.retry-orientation-thanks {
  text-align: center;
  z-index: 10000;
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: absolute;
}

.ls-video-instructions-cont {
  z-index: 10000;
  background-color: #fff;
  width: 100%;
  height: 100%;
  position: relative;
}

.ls-privacy-policy-header {
  text-align: center;
  padding-bottom: 2rem;
}

.ls-privacy-policy-contact-details {
  padding-bottom: 2rem;
}

.ls-privacy-policy-contact-details h3 {
  font-size: 1.5rem;
}

.ls-privacy-policy-contact-details p {
  margin-bottom: unset;
  font-size: 1rem;
}

.ls-privacy-policy-section {
  padding-bottom: 1rem;
}

.ls-privacy-policy-section h3 {
  font-size: 1.5rem;
}

.ls-privacy-policy-section p {
  margin-bottom: .8rem;
  font-size: 1rem;
}

.ls-privacy-policy-sections, .ls-privacy-policy-data-protection {
  padding-bottom: 2rem;
}

.ls-privacy-policy-data-protection h2 {
  font-size: 1.5rem;
}

.ls-privacy-policy-data-protection-body p {
  font-size: 1.2rem;
}

.ls-privacy-policy-data-protection-bullet p {
  margin-bottom: .5rem;
  font-size: 1rem;
}

.ls-privacy-policy-complaints {
  padding-bottom: 2rem;
}

.ls-privacy-policy-complaints h2 {
  font-size: 1.5rem;
}

.ls-privacy-policy-complaints p {
  margin-bottom: .5rem;
  font-size: 1rem;
}

.ls-privacy-policy-complaints-body {
  margin-bottom: 2rem;
}

.ls-privacy-policy-complaints-body p {
  margin-bottom: .5rem;
  font-size: 1rem;
}

.ls-privacy-policy-complaints-address {
  margin-bottom: 1rem;
}

.ls-privacy-policy-complaints-address h3 {
  font-size: 1.5rem;
}

.ls-privacy-policy-complaints-address p {
  margin-bottom: unset;
  font-size: 1rem;
}

@media (width >= 813px), (height >= 421px) {
  .mobileOnly {
    display: none;
  }
}

@media (width <= 812px), (height <= 420px) {
  .ls-header {
    height: 20px;
    margin-bottom: 0;
  }

  .ls-header-cont, .desktopOnly {
    display: none;
  }

  .ls-footer-mob {
    margin-right: auto;
    display: block;
  }

  .ls-modal {
    width: calc(100% - 60px);
    padding: 20px;
  }

  .ls-modalclose {
    font-size: 20px;
    right: -29px;
  }

  .sliderImg {
    max-width: 150px;
  }

  .progress {
    width: 100%;
    position: absolute;
    top: 20px;
  }

  .logo {
    display: none;
  }

  .questionRow input[type="checkbox"], .questionRow input[type="radio"] {
    transform: translateY(-66%)scale(1.5);
  }

  .questionRow .radioAnswerCont, .questionRow .checkboxAnswerCont {
    border: 1px solid var(--ui-primary-color);
    padding: 10px;
  }

  #lr_show_video {
    max-width: 100%;
    max-height: 100%;
  }

  .zoom-test {
    width: 100%;
    height: calc(100vh - 90px);
  }

  .ls-privacy-policy-header {
    text-align: center;
    padding-bottom: 2rem;
  }

  .ls-privacy-policy-contact-details {
    padding-bottom: 2rem;
  }

  .ls-privacy-policy-contact-details h3 {
    font-size: .8rem;
  }

  .ls-privacy-policy-contact-details p {
    margin-bottom: unset;
    font-size: .8rem;
  }

  .ls-privacy-policy-section {
    padding-bottom: 1rem;
  }

  .ls-privacy-policy-section h3 {
    font-size: .8rem;
  }

  .ls-privacy-policy-section p {
    margin-bottom: .8rem;
    font-size: .8rem;
  }

  .ls-privacy-policy-sections, .ls-privacy-policy-data-protection {
    padding-bottom: 2rem;
  }

  .ls-privacy-policy-data-protection h2, .ls-privacy-policy-data-protection-body p {
    font-size: .8rem;
  }

  .ls-privacy-policy-data-protection-bullet p {
    margin-bottom: .5rem;
    font-size: .8rem;
  }

  .ls-privacy-policy-complaints {
    padding-bottom: 2rem;
  }

  .ls-privacy-policy-complaints h2 {
    font-size: .8rem;
  }

  .ls-privacy-policy-complaints p {
    margin-bottom: .5rem;
    font-size: .8rem;
  }

  .ls-privacy-policy-complaints-body {
    margin-bottom: 2rem;
  }

  .ls-privacy-policy-complaints-body p {
    margin-bottom: .5rem;
    font-size: .8rem;
  }

  .ls-privacy-policy-complaints-address {
    margin-bottom: 1rem;
  }

  .ls-privacy-policy-complaints-address h3 {
    font-size: .8rem;
  }

  .ls-privacy-policy-complaints-address p {
    margin-bottom: unset;
    font-size: .8rem;
  }
}

@media (width <= 575px) {
  .test-splash-page h4 {
    font-size: 1rem;
  }

  .test-splash-page p {
    font-size: .8rem;
  }
}

@media (height <= 420px) {
  .ls-footer {
    height: 50px;
  }

  .menu {
    margin: 5px 0;
  }

  .btn-primary {
    margin: 3px 0;
    font-size: 1.3rem;
  }

  .ls-footer .menu-cont button {
    margin-bottom: 6px;
    font-size: 1em;
  }

  #positioningInstructions {
    top: 50px !important;
  }

  #positioningInstructions:nth-child(2) {
    top: auto !important;
    bottom: 20px !important;
  }

  #instimg, #instimg2 {
    top: 10px !important;
  }

  #tapimg, #tapimg2 {
    top: auto !important;
    bottom: 10px !important;
  }

  .instructionsText {
    margin-top: 50px;
  }

  .studyCont pre {
    font-size: 1.2rem;
  }

  .zoom-test {
    width: 100%;
    height: calc(100vh - 70px);
  }
}

@media (height <= 644px) {
  body {
    line-height: 1;
  }

  .sliderImg {
    max-width: 150px;
  }
}

@media (height <= 375px) {
  .sliderImg {
    max-width: 70px;
  }
}

@media (width >= 576px) and (width <= 991px) {
  .container {
    max-width: 960px;
  }
}

.shelf-prompt {
  background: #fff;
  border: 1px solid;
  border-radius: 16px;
  flex-direction: column;
  min-width: 300px;
  max-width: 80vw;
  padding: 1rem;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.shelf-prompt-text {
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  display: flex;
}

.ls-skip-survey {
  flex-direction: column;
  align-items: center;
  font-size: 1.6rem;
  display: flex;
}

.ls-skip-survey-text {
  margin-bottom: 2rem;
}

.ls-skip-survey-buttons > button {
  margin: 0 8px;
}

.instructions-survey-results {
  flex-direction: column;
  align-items: center;
  font-size: 1.6rem;
  display: flex;
}

.instructions-survey-result {
  display: flex;
}

.instructions-survey-result > div {
  padding: .5rem;
}

.screenout-check-webcam {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80%;
  display: flex;
}

.screenout-check-webcam > div:first-of-type {
  margin-bottom: 4rem;
  font-size: 1.2rem;
  font-weight: 600;
}

.screenout-check-webcam-remaining {
  font-size: 4rem;
}

.ls-video-placeholder {
  opacity: 1;
  z-index: -1;
  width: 50px;
  height: 50px;
  position: fixed;
  top: 0;
  left: 0;
}

@media (width >= 576px) {
  .ls-footer-cont, .ls-header-cont {
    max-width: 540px;
  }

  .ls-privacy-policy {
    width: 540px;
  }
}

@media (width >= 768px) {
  .ls-footer-cont, .ls-header-cont {
    max-width: 720px;
  }

  .ls-privacy-policy {
    width: 720px;
  }
}

@media (width >= 992px) {
  .ls-footer-cont, .ls-header-cont {
    max-width: 960px;
  }

  .ls-privacy-policy {
    width: 960px;
  }
}

@media (width >= 1200px) {
  .ls-footer-cont, .ls-header-cont {
    max-width: 1140px;
  }

  .ls-privacy-policy {
    width: 1140px;
  }
}

:root {
  --primary-color: #fff;
  --compliment-color: #000;
  --ui-primary-color: #fff;
  --ui-secondary-color: #aaa;
  --ui-text-color: #000;
}

.ls-overlay {
  background-color: #fff;
  grid-area: 1 / 1;
  width: 100%;
  position: relative;
}

.ls-modal-overlay {
  z-index: 100;
  background-color: #fff;
  width: 100%;
  height: 100%;
  position: fixed;
}

.ls-loading {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

@keyframes lds-ripple {
  0% {
    opacity: 1;
    width: 0;
    height: 0;
    top: 92px;
    left: 92px;
  }

  100% {
    opacity: 0;
    width: 152px;
    height: 152px;
    top: 16px;
    left: 16px;
  }
}

.lds-ripple {
  display: inline-block;
  position: relative;
}

.lds-ripple div {
  box-sizing: content-box;
  opacity: 1;
  border-style: solid;
  border-width: 8px;
  border-radius: 50%;
  animation: 2s cubic-bezier(0, .2, .8, 1) infinite lds-ripple;
  position: absolute;
}

.lds-ripple div:first-child {
  border-color: var(--ui-secondary-color);
}

.lds-ripple div:nth-child(2) {
  border-color: var(--compliment-color);
  animation-delay: -1s;
}

.lds-ripple {
  transform: translate(-100px, -100px)scale(1)translate(100px, 100px);
  width: 200px !important;
  height: 200px !important;
}

@media (width <= 575px) {
  .lds-ripple {
    transform: translate(-50px, -50px)scale(.5)translate(50px, 50px);
    width: 100px !important;
    height: 100px !important;
  }

  .ls-loading h1 {
    font-size: 2rem;
  }
}
/*# sourceMappingURL=index.3e0209d4.css.map */
