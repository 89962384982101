/* <<<<<<<< InstructionStep >>>>>>>> */

.ls-instruction-cont {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 100%;
  width: 100%;
}

.ls-instruction-cont.col {
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
}

.ls-instruction-text {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.5rem;
  white-space: pre-wrap;
  flex-grow: 1;
  width: 0px;
}

.ls-instruction-cont.col > .ls-instruction-text {
  width: 100%;
}

.ls-instruction-image {
  margin: 1rem;
  opacity: 0;
}

.ls-instruction-image.view {
  opacity: 1;
}

.ls-instruction-image.max-width {
  max-width: 100%;
  opacity: 1;
  margin-top: 2rem;
}

.ls-instruction-image.max-height {
  flex-grow: 1;
  opacity: 1;
  margin-top: 2rem;
}

@media (max-width: 812px), (max-height: 420px) {
  .ls-instruction-cont {
    padding-top: 10px;
  }
}

/* Bootsrap: xs */
@media (max-width: 575px) {
  .ls-instruction-defimage {
    display: none;
  }
}

.ls-prompt {
  z-index: 1000;
  position: fixed;
  background: white;
  border: solid 1px black;
  border-radius: 1rem;
  padding: 2rem;
  font-size: 1.5rem;
  font-weight: bold;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
}

.ls-shelf-cont {
  position: absolute;
}

.ls-shelf-hidden {
  visibility: hidden;
  position: relative;
  left: auto;
  top: auto;
  transform: none;
  object-fit: scale-down;
  max-height: 100% !important;
}

.ls-shelf-img.max-width {
  width: 100%;
}

.ls-shelf-img.max-height {
  height: 100%;
}

/* <<<<<<<< Stimulus Step >>>>>>>> */

.ls-scroll-stim {
  width: 100%;
}

/* <<<<<<<< ZoomStep >>>>>>>> */

.ls-zoom-lens {
  position: absolute;
  border: 1px solid #d4d4d4;
  visibility: hidden;
}

.ls-zoom-src {
  position: absolute;
  z-index: 4;
  max-width: 100% !important;
  max-height: 100% !important;
}

.ls-zoom-result {
  border: 1px solid #d4d4d4;
  position: absolute;
  overflow: hidden;
  visibility: hidden;
  z-index: 3;
  max-width: 100% !important;
  max-height: 100% !important;
}

.ls-zoom-result-img {
  position: absolute;
}

/* <<<<<<<< SocialStep >>>>>>>> */

.ls-social-cont {
  position: relative;
  height: 100vh;
  width: 100vw;
}

.ls-social-overlay {
  z-index: 1000000;
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: white;
}

/* <<<<<<<< VideoStep >>>>>>>> */

.ls-video {
  position: relative;
  height: calc(100% - 1rem);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ls-video-fullscreen{
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  background-color: black;
  height: 100dvh;
  z-index: 1000;
}

.ls-video-cont {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ls-video-player {
  max-width: 100%;
  max-height: 100%;
}

.ls-video-playbtn {
  position: absolute;
  height: 50px;
  width: 80px;
  background-color: white;
  border: solid 1px black;
  border-radius: 1rem;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-sizing: border-box;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.ls-video-controls {
  display: flex;
  position: absolute;
  background-color: white;
  bottom: 5%;
  left: 5%;
  width: 90%;
  height: 30px;
  align-items: center;
  padding: 0 5px;
  box-sizing: border-box;
  z-index: 1000;
}

.ls-video-playmini {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: bold;
  margin-right: 10px;
}

.ls-video-prog {
  position: relative;
  flex-grow: 1;
  height: 7px;
}

.ls-video-prog > div:nth-of-type(1) {
  background-color: black;
  height: 7px;
  position: absolute;
  z-index: 100;
}

.ls-video-prog > div:nth-of-type(2) {
  background-color: gainsboro;
  width: 100%;
  height: 7px;
  position: absolute;
}

.ls-video-vol {
  position: relative;
  width: 24px;
  height: 24px;
  margin-left: 10px;
  cursor: pointer;
}

.ls-video-vol-control {
  position: absolute;
  height: 100px;
  width: 21px;
  background-color: white;
  top: 0px;
  left: 0px;
  transform: translate(4px, -101%);
  padding: 7px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-end;
}

.ls-video-vol-control > div:nth-of-type(1) {
  background-color: black;
  width: 7px;
  position: absolute;
  left: 7px;
  z-index: 100;
}

.ls-video-vol-control > div:nth-of-type(2) {
  background-color: gainsboro;
  width: 7px;
  height: calc(100% - 14px);
  left: 7px;
  position: absolute;
}

.ls-video-vol-control > div:nth-of-type(3) {
  background-color: black;
  width: 12px;
  height: 12px;
  transform: translate(-2.5px, 2px);
  left: 7px;
  position: absolute;
  border-radius: 11px;
  z-index: 110;
}

.ls-video-instructions-cont {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background-color: white;
}

.ls-video-player::-webkit-media-controls {
  display: none;
}

.ls-video-player::-webkit-media-controls-enclosure {
  display: none !important;
}

/* <<<<<<<< Bespoke Step >>>>>>>> */

.ls-bespoke-step {
  height: 100vh;
  height: 100dvh;
  position: fixed;
  width: 100vw;
  z-index: 100000;
  top: 0px;
}

.ls-bespoke-button-cont {
  position: absolute;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20%;
}

.ls-bespoke-button-cont.ls-bespoke-button-bottom{
  bottom: 0px;
}

.ls-bespoke-button-cont.ls-bespoke-button-middle{
  top: 50%;
  transform: translate(0px, -50%);
}

.ls-bespoke-button-cont.ls-bespoke-button-top{
  top: 0px;
}

.ls-bespoke-button {
  min-width: 80%;
  border-radius: 6px;
  padding: 8px;
  cursor: pointer;
  font-weight: bold;
}

.ls-bespoke-video{
  height: 100%;
  width: 100%;
}