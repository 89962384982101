* {
  text-rendering: optimizeLegibility;
}

html {
  max-width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  padding-bottom: 70px;
}

body,
#root,
.ls {
  height: inherit;
  max-width: 100%;
  color: var(--ui-text-color);
  background-color: var(--primary-color);
  display: flex;
  flex-direction: column;
}

.ls-body {
  position: relative;
  flex-grow: 1;
  height: 0px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.ls-body::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.ls-content {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: scroll;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

.ls-content::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}


.ls.debug,
.ls.demographics,
.ls.index {
  overflow: auto;
}

.ls-button {
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 600;
  border: 1px solid var(--ui-secondary-color);
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  margin: 2rem 0rem;
  vertical-align: middle;
  line-height: 1.5;
  position: relative;
  cursor: pointer;
}

/* .lang-ar h1, .lang-ar h2, .lang-ar h3, .lang-ar h4, .lang-ar h5, .lang-ar h6, .lang-ar p, .lang-ar span {
  text-align: right;
} */

.lang-ar {
  text-align: right;
}

body {
  font-family: "Source Sans Pro", sans-serif;
}

.ls-social-cont img {
  max-height: unset !important;
  max-width: unset !important;
}

.studyCont
  img:not(.ls-instruction-image):not(.ls-shelf-hidden):not(.ls-zoom-result-img):not(.retry-orientation-imgtag) {
  max-width: 100% !important;
}

.studyCont
  img:not(.ls-instruction-image):not(.ls-shelf-hidden):not(.ls-zoom-result-img):not(.retry-orientation-imgtag) {
  max-height: calc(100% - 20px) !important;
}

#dark2 img {
  transform: none;
}

a,
a:not([href]):not([tabindex]),
a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover,
.link {
  color: var(--ui-text-color);
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
}

a:hover,
a:not([href]):not([tabindex]):hover,
.link:hover {
  cursor: pointer;
}

p,
a {
  font-size: 1.2rem;
}

button,
.btn,
.btn-primary {
  background-color: var(--ui-primary-color);
  border-color: var(--ui-secondary-color);
  color: var(--ui-text-color);
}

button:hover,
.btn:hover,
.btn-primary:hover,
.btn-primary:active {
  background-color: var(--ui-secondary-color);
  border-color: var(--ui-secondary-color);
  color: #fff;
}

textarea,
input {
  border: 1px solid var(--ui-primary-color);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #8e8e8e;
  border-color: #8e8e8e;
}

/* Currently hidden when disabled - footer only */
.ls-footer .btn-primary.disabled,
.ls-footer .btn-primary:disabled {
  display: none;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.button-primary {
  color: var(--ui-text-color);
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 600;
  margin-top: 4px;
  position: relative;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: var(--ui-secondary-color);
  border-color: var(--ui-secondary-color);
}

.button-secondary {
  color: var(--ui-text-color);
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 600;
  margin-top: 4px;
  position: relative;
}

.start-button {
  background-color: #26b13f;
  border-color: #26b13f;
}

.start-button:hover,
.start-button:active,
.start-button:focus {
  background-color: #187229;
  border-color: #187229;
}

.invalidate-button {
  background-color: #b12653;
  border-color: #b12653;
}

.invalidate-button:hover,
.invalidate-button:active,
.invalidate-button:focus {
  background-color: #721835;
  border-color: #721835;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

.progress {
  border-radius: 0;
  height: 4px;
}

.progress-bar {
  background-color: var(--ui-primary-color);
}

.block {
  display: block;
}

.absoluteCenter {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}

.centerContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.centerAlign {
  text-align: center;
}

.rightAlign {
  text-align: right;
}

.floatRight {
  float: right;
}

.logo {
  width: 100%;
  max-width: 200px;
  min-width: 150px;
}

.ls-header {
  background: var(--primary-color);
  height: 70px;
  /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); */
  margin-bottom: 20px;
  z-index: 2;
  position: relative;
  border-bottom: 1px solid var(--ui-secondary-color);
  text-align: left;
  overflow: hidden;
}

.ls-footer-cont,
.ls-header-cont {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  height: 100%;
}

.ls-footer-mob {
  display: none;
}

.ls-footer-preview,
.ls-footer-index,
.ls-footer-study{
  display: flex;
  justify-content: flex-end;
}

.ls-footer .ls-button{
  margin: auto 0px;
}

.menu {
  font-size: 2.5rem;
  color: var(--compliment-color);
  margin: 14px 0;
}

.menu:hover {
  cursor: pointer;
}

.menu-cont {
  position: absolute;
}

.ls-header .menu-cont button {
  float: left;
  clear: both;
  margin-bottom: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  text-transform: uppercase;
}

.ls-footer {
  background: var(--primary-color);
  height: 70px;
  /* box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); */
  width: 100%;
  border-top: 1px solid var(--ui-secondary-color);
  text-align: left;
  overflow: hidden;
}

.ls-footer .menu-cont {
  transform: translateY(-100%) translateY(-70px);
}

.ls-footer .menu-cont button {
  float: left;
  clear: both;
  margin-bottom: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  text-transform: uppercase;
}

.ls-main {
  text-align: center;
  position: relative;
}

/* .ls-loading {
  z-index: -1;
} */

.help-cont {
  width: 100%;
  height: 100%;
  max-height: 68px;
  position: relative;
  cursor: pointer;
  top: 2px;
}

.obscure {
  width: 100%;
  height: 100%;
  position: relative;
  background: var(--primary-color);
  max-height: 70px;
  top: -2px;
}

.help-tag {
  color: var(--ui-text-color);
  width: 50%;
  text-transform: uppercase;
  text-align: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  background: var(--ui-primary-color); /* Old browsers */
  /*
  background: -moz-linear-gradient(top, var(--primary-color) 0%, var(--ui-primary-color) 100%);
  background: -webkit-linear-gradient(top, var(--primary-color) 0%,var(--ui-primary-color) 100%);
  background: linear-gradient(to bottom, var(--primary-color) 0%,var(--ui-primary-color) 100%);
  */
  z-index: -1;
  padding: 3px;
  border-radius: 0 0 5px 5px;
  position: absolute;
  right: 10px;
  margin-top: -42px;
  transition: all 0.3s ease-in-out;
  font-size: 2rem;
  font-weight: 600;
}

.help-tag.help-tag-active {
  margin-top: -2px;
}

.ls-modal {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 2;
  background: white;
  padding: 50px;
  border: 5px solid var(--ui-secondary-color);
  height: 90vh;
  display: flex;
  flex-direction: column;
}

.ls-modalbg {
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.5;
  filter: blur(50px);
}

.ls-modalclose {
  font-size: 30px;
  position: absolute;
  right: -32px;
  top: -30px;
  background: white;
  padding: 10px 14px;
  border: 5px solid var(--ui-primary-color);
  border-radius: 50px;
}

.ls-modalclose {
  cursor: pointer;
}

.ls-modalContentWrap {
  overflow-y: auto;
  max-height: 616px;
  overflow-x: hidden;
}

.sliderImg {
  margin: 20px 0;
  max-width: 300px;
  display: inline-block !important;
}

.slick-slide {
  text-align: center;
}

.slick-next,
.slick-prev {
  top: 30%;
  width: 50px;
  height: 50px;
  z-index: 2;
}

.slick-prev {
  left: 0px;
}

.slick-next {
  right: 0px;
}

.slick-prev:before,
.slick-next:before {
  color: grey;
  font-size: 50px;
}

.slick-prev:before {
  content: "\2190";
}

.slick-next:before {
  content: "\2192";
}

.slick-dots li button:before {
  content: "\2022";
}

.finalInstructionSlick {
  margin-top: 50px;
}

.instructionsText {
  margin-top: 50px;
}

.setupCont,
.studyCont {
  height: 100%;
  /* position: relative; */
}

.studyCont > .row,
.studyCont > .row > .col-12,
.studyCont .ls-main {
  height: 100%;
}

.studyCont
  img:not(.ls-instruction-image):not(.ls-shelf-hidden):not(.ls-zoom-result-img):not(.ls-scroll-stim):not(.retry-orientation-imgtag) {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.studyCont #calibrationElement #arrimg {
  position: fixed;
  left: 50%;
  top: calc(50% - 80px);
}

.ls-lumenShelfAoi {
  background: rgba(0, 0, 0, 0.1);
}

.ls-lumenShelfAoi:hover,
.ls-lumenShelfAoi:focus {
  background: rgba(0, 0, 0, 0.3);
}

.studyCont pre {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.5rem;
  overflow: hidden;
  white-space: pre-wrap;
}

.studyCont pre b {
  font-weight: 900;
}

.lumenIframeStandard {
  width: 100vw;
  border: none;
  position: fixed;
  transform: translate(-50%);
  display: inline;
  visibility: hidden; /* made visible in lumen-common */
  z-index: 3;
  height: calc(var(--vh, 1vh) * 100);
}

.lumenIframePostDemographics {
  width: 100vw;
  border: none;
  position: fixed;
  display: inline;
  visibility: hidden; /* made visible in lumen-common */
  z-index: 3;
  height: calc(var(--vh, 1vh) * 100);
}

.lumenIframeDemographics {
  transform: none;
  width: 100%;
  border: none;
  position: absolute;
  display: inline;
  visibility: hidden; /* made visible in lumen-common */
  z-index: 2;
  height: calc(var(--vh, 1vh) * 100);
}

/*
#calibrationElement, #headPositionElement, #validationElement {
  z-index: 200;
  width: 100% !important;
  height: 100% !important;
}
*/

#headPositionElement {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.5);
  display: none;
}

#lr_show_video {
  max-width: 50%;
  max-height: 50%;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) rotateY(180deg) !important;
}

#calibrationElement {
  left: 0px;
  top: 0px;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100% !important;
  display: none;
  z-index: 2;
}

#validationElement {
  left: 0px;
  top: 0px;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100% !important;
  display: none;
  z-index: 2;
}

#loader_calib {
  position: absolute;
}

/* TRANSITIONS and ANIMATIONS */

.toggle-fade-enter {
  opacity: 0.01;
}

.toggle-fade-enter.toggle-fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.toggle-fade-leave {
  opacity: 1;
}

.toggle-fade-leave.toggle-fade-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.questionRow {
  text-align: left;
  overflow: auto;
  height: 100%;
}

.questionRow form {
  width: 100%;
  margin-bottom: 20px;
}

.questionRow form div {
  text-align: left;
}

.questionRow input:not([type="text"]) {
  margin-right: 10px;
  top: 50%;
  position: absolute;
  transform: translateY(-66%);
  height: 15px;
}

.questionRow textarea,
.questionRow input[type="text"] {
  width: 100%;
  max-width: 400px;
  margin-top: 10px;
  font-size: 16px !important;
  padding: 1px 2px;
  border: 1px solid var(--compliment-color);
}

.questionRow textarea {
  min-height: 100px;
}

.questionRow .radioLabel {
  width: calc(100% - 23px);
  margin-left: 24px;
  font-size: 1.05rem;
}

.questionRow .radioAnswerCont,
.questionRow .checkboxAnswerCont {
  position: relative;
  margin-bottom: 10px;
}

.questionRow .questionError {
  color: #dc3545;
}

.questionCont {
  transition: visibility 0s, opacity 0.5s linear;
}

.questionCont:not(.active) {
  visibility: hidden;
  opacity: 0;
  height: 0;
  position: fixed;
}

.btn-question {
  margin-bottom: 20px;
}

.retry-instructions {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.retry-mobile-fullscreen {
  height: 150vh;
  width: 100vw;
  font-weight: 500;
  overflow-y: scroll;
}

.retry-mobile-fullscreen-escape {
  position: fixed;
  font-size: 1rem;
  top: 0px;
  left: 0px;
  cursor: pointer;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background-color: white;
}

.retry-mobile-fullscreen-text {
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

.retry-mobile-fullscreen-thankyou {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.fullscreen-test {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 10000;
  background-color: white;
  font-size: 1.5rem;
  font-weight: 500;
}

.fullscreen-test > div.fullscreen-test-instructions {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: white;
}

.fullscreen-test-instructions {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
  padding: 0 4rem;
}

.zoom-test-box {
  position: absolute;
  padding: 2rem;
  border: solid 1px black;
  border-radius: 8px;
  width: 135px;
}

.zoom-test-drop {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 10vh;
  width: 10vw;
  border: solid 1px black;
  border-radius: 8px;
}

.retry-orientation {
  height: 100vh;
  display: flex;
  width: 100vw;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  justify-content: center;
}

.studyCont .retry-orientation {
  position: fixed;
  top: 0px;
  left: 0px;
}

.retry-orientation-text {
  text-align: center;
}

.retry-orientation-rotate {
  font-size: 4rem;
  padding: 2rem;
}

.retry-orientation-img {
  margin-top: 5rem;
  max-width: 60vw;
  max-height: 60vh;
}

.retry-orientation-img > img {
  width: 100%;
  height: 100%;
}

.retry-orientation-thanks {
  position: absolute;
  height: 100vh;
  display: flex;
  width: 100vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: white;
  z-index: 10000;
}

.ls-video-instructions-cont{
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background-color: white;
}

/* ||||||| privacy policy ||||||| */

.ls-privacy-policy-header {
  text-align: center;
  padding-bottom: 2rem;
}

.ls-privacy-policy-contact-details{
  padding-bottom: 2rem;
}

.ls-privacy-policy-contact-details h3 {
  font-size: 1.5rem;
}

.ls-privacy-policy-contact-details p {
  margin-bottom: unset;
  font-size: 1rem;
}

.ls-privacy-policy-section{
  padding-bottom: 1rem;
}

.ls-privacy-policy-section h3 {
  font-size: 1.5rem;
}

.ls-privacy-policy-section p {
  font-size: 1rem;
  margin-bottom: 0.8rem;
}

.ls-privacy-policy-sections{
  padding-bottom: 2rem;
}

.ls-privacy-policy-data-protection{
  padding-bottom: 2rem;
}

.ls-privacy-policy-data-protection h2 {
  font-size: 1.5rem;
}

.ls-privacy-policy-data-protection-body p {
  font-size: 1.2rem;
}

.ls-privacy-policy-data-protection-bullet p {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.ls-privacy-policy-complaints {
  padding-bottom: 2rem;
}

.ls-privacy-policy-complaints h2 {
  font-size: 1.5rem;
}

.ls-privacy-policy-complaints p {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}


.ls-privacy-policy-complaints-body {
  margin-bottom: 2rem;
}

.ls-privacy-policy-complaints-body p {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.ls-privacy-policy-complaints-address {
  margin-bottom: 1rem;
}

.ls-privacy-policy-complaints-address h3 {
  font-size: 1.5rem;
}

.ls-privacy-policy-complaints-address p {
  font-size: 1rem;
  margin-bottom: unset;
}

/* Responsive Styles */

@media (min-width: 813px), (min-height: 421px) {
  .mobileOnly {
    display: none;
  }
}

@media (max-width: 812px), (max-height: 420px) {
  .ls-header {
    height: 20px;
    margin-bottom: 0;
  }

  .ls-header-cont,
  .desktopOnly {
    display: none;
  }

  .ls-footer-mob {
    display: block;
    margin-right: auto;
  }

  .ls-modal {
    width: calc(100% - 60px);
    padding: 20px;
  }
  .ls-modalclose {
    font-size: 20px;
    right: -29px;
  }
  .sliderImg {
    max-width: 150px;
  }
  .progress {
    position: absolute;
    top: 20px;
    width: 100%;
  }
  .logo {
    display: none;
  }
  .questionRow input[type="checkbox"],
  .questionRow input[type="radio"] {
    transform: translateY(-66%) scale(1.5);
  }
  .questionRow .radioAnswerCont,
  .questionRow .checkboxAnswerCont {
    border: 1px solid var(--ui-primary-color);
    padding: 10px;
  }

  /* #calibrationElement, #validationElement {
    top: 30px;
  } */
  #lr_show_video {
    max-width: 100%;
    max-height: 100%;
  }
  .zoom-test {
    height: calc(100vh - 90px);
    width: 100%;
  }

/* ||||||| privacy policy ||||||| */

  .ls-privacy-policy-header {
    text-align: center;
    padding-bottom: 2rem;
  }

  .ls-privacy-policy-contact-details{
    padding-bottom: 2rem;
  }

  .ls-privacy-policy-contact-details h3 {
    font-size: 0.8rem;
  }

  .ls-privacy-policy-contact-details p {
    margin-bottom: unset;
    font-size: 0.8rem;
  }

  .ls-privacy-policy-section{
    padding-bottom: 1rem;
  }

  .ls-privacy-policy-section h3 {
    font-size: 0.8rem;
  }

  .ls-privacy-policy-section p {
    font-size: 0.8rem;
    margin-bottom: 0.8rem;
  }

  .ls-privacy-policy-sections{
    padding-bottom: 2rem;
  }

  .ls-privacy-policy-data-protection{
    padding-bottom: 2rem;
  }

  .ls-privacy-policy-data-protection h2 {
    font-size: 0.8rem;
  }

  .ls-privacy-policy-data-protection-body p {
    font-size: 0.8rem;
  }

  .ls-privacy-policy-data-protection-bullet p {
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
  }

  .ls-privacy-policy-complaints {
    padding-bottom: 2rem;
  }

  .ls-privacy-policy-complaints h2 {
    font-size: 0.8rem;
  }

  .ls-privacy-policy-complaints p {
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
  }


  .ls-privacy-policy-complaints-body {
    margin-bottom: 2rem;
  }

  .ls-privacy-policy-complaints-body p {
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
  }

  .ls-privacy-policy-complaints-address {
    margin-bottom: 1rem;
  }

  .ls-privacy-policy-complaints-address h3 {
    font-size: 0.8rem;
  }

  .ls-privacy-policy-complaints-address p {
    font-size: 0.8rem;
    margin-bottom: unset;
  }
}

/* Bootsrap: xs */
@media (max-width: 575px) {
  .test-splash-page h4 {
    font-size: 1rem;
  }

  .test-splash-page p {
    font-size: 0.8rem;
  }
}

/* Landscape */
@media (max-height: 420px) {
  .ls-footer {
    height: 50px;
  }
  .menu {
    margin: 5px 0;
  }
  .btn-primary {
    font-size: 1.3rem;
    margin: 3px 0;
  }
  .ls-footer .menu-cont button {
    font-size: 1em;
    margin-bottom: 6px;
  }
  #positioningInstructions {
    top: 50px !important;
  }
  #positioningInstructions:nth-child(2) {
    bottom: 20px !important;
    top: auto !important;
  }
  #instimg,
  #instimg2 {
    top: 10px !important;
  }
  #tapimg,
  #tapimg2 {
    top: auto !important;
    bottom: 10px !important;
  }
  .instructionsText {
    margin-top: 50px;
  }
  .studyCont pre {
    font-size: 1.2rem;
  }
  .zoom-test {
    height: calc(100vh - 70px);
    width: 100%;
  }
}

@media (max-height: 644px) {
  body {
    line-height: 1;
  }
  .sliderImg {
    max-width: 150px;
  }
}
@media (max-height: 375px) {
  .sliderImg {
    max-width: 70px;
  }
}

@media (min-width: 576px) and (max-width: 991px) {
  .container {
    max-width: 960px;
  }
}

.shelf-prompt {
  background: white;
  position: absolute;
  left: 50%;
  top: 50%;
  max-width: 80vw;
  min-width: 300px;
  transform: translate(-50%, -50%);
  border: solid 1px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.shelf-prompt-text {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.ls-skip-survey{
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.6rem;
}

.ls-skip-survey-text {
  margin-bottom: 2rem;
}

.ls-skip-survey-buttons > button {
  margin: 0px 8px;
}

.instructions-survey-results {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.6rem;
}

.instructions-survey-result {
  display: flex;
}

.instructions-survey-result > div {
  padding: 0.5rem;
}

.screenout-check-webcam {
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.screenout-check-webcam > div:first-of-type {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 4rem;
}

.screenout-check-webcam-remaining {
  font-size: 4rem;
}

.ls-video-placeholder{
  opacity: 1;
  position: fixed;
  z-index: -1;
  top: 0px;
  left: 0px;
  height: 50px;
  width: 50px;
}

@media (min-width: 576px) {
  .ls-footer-cont,
  .ls-header-cont {
    max-width: 540px;
  }
  .ls-privacy-policy{
    width: 540px;
  }
}

@media (min-width: 768px) {
  .ls-footer-cont,
  .ls-header-cont {
    max-width: 720px;
  }
  .ls-privacy-policy{
    width: 720px;
  }
}

@media (min-width: 992px) {
  .ls-footer-cont,
  .ls-header-cont {
    max-width: 960px;
  }
  .ls-privacy-policy{
    width: 960px;
  }
}

@media (min-width: 1200px) {
  .ls-footer-cont,
  .ls-header-cont {
    max-width: 1140px;
  }
  .ls-privacy-policy{
    width: 1140px;
  }
}
