.ls-preview-tab {
  position: fixed;
  left: 100vw;
  top: 75px;
  z-index: 999999;
  /* transform: translate(-250px, 0px); */
}

.ls-preview-tab-button {
  background-color: white;
  position: absolute;
  height: 50px;
  width: 27px;
  left: -25px;
  top: 16px;
  border-radius: 5px 0px 0px 5px;
  border: solid 2px black;
  border-right: 0px;
  cursor: pointer;
}

.ls-preview-tab-content {
  height: calc(100vh - 158px);
  background-color: white;
  width: 250px;
  padding: 16px 8px;
  display: flex;
  border: solid 2px black;
  align-items: center;
  border-radius: 8px;
  flex-direction: column;
}

.ls-preview-tab-status {
  background-color: goldenrod;
  padding: 4px 8px;
  margin-bottom: 16px;
  font-weight: bold;
  border-radius: 5px;
}

.ls-preview-tab-badge,
.ls-preview-tab-item {
  margin-bottom: 4px;
  width: 100%;
  display: flex;
}

.ls-preview-tab-badge-label {
  flex-grow: 1;
}

.ls-preview-tab-badge-value {
  min-width: 25px;
  padding: 2px 4px;
  border-radius: 5px;
  font-weight: bold;
}

.ls-preview-tab-item > div {
  flex-grow: 1;
}

.ls-preview-timer {
  height: 16px;
  padding: 4px;
  display: grid;
}

.ls-preview-timer-prog {
  height: 8px;
  grid-row-start: 1;
  grid-column-start: 1;
  background-color: black;
  z-index: 2;
}

.ls-preview-timer-bar {
  height: 8px;
  width: 100%;
  background-color: gainsboro;
  grid-row-start: 1;
  grid-column-start: 1;
}

.ls-preview-tab-extra {
  width: 100%;
  margin-top: 2rem;
}
