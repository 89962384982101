.ls-base-page {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  flex-direction: column;
  text-align: center;
  margin: auto;
  position: relative;
  grid-column: 1;
  grid-row: 1;
}

.ls-back-pressed {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  overflow: hidden;
  flex-direction: column;
  text-align: center;
  margin: auto;
  position: relative;
  grid-column: 1;
  grid-row: 1;
}

@media (max-width: 575px) {
  .ls-base-page {
    padding: 0 0.5rem;
  }
}

.ls-flex-start {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.ls-step-cont {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  flex-direction: column;
}

@media (min-width: 576px) {
  .ls-base-page {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .ls-base-page {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .ls-base-page {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .ls-base-page {
    max-width: 1140px;
  }
}
