.ls-overlay {
  position: relative;
  width: 100%;
  /* height: 100%; */
  background-color: white;
  grid-row: 1;
  grid-column: 1;
}

.ls-modal-overlay{
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 100;
}

.ls-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

@keyframes lds-ripple {
  0% {
    top: 92px;
    left: 92px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 16px;
    left: 16px;
    width: 152px;
    height: 152px;
    opacity: 0;
  }
}
@-webkit-keyframes lds-ripple {
  0% {
    top: 92px;
    left: 92px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 16px;
    left: 16px;
    width: 152px;
    height: 152px;
    opacity: 0;
  }
}
.lds-ripple {
  position: relative;
  display: inline-block;
}
.lds-ripple div {
  box-sizing: content-box;
  position: absolute;
  border-width: 8px;
  border-style: solid;
  opacity: 1;
  border-radius: 50%;
  -webkit-animation: lds-ripple 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  animation: lds-ripple 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(1) {
  border-color: var(--ui-secondary-color);
}
.lds-ripple div:nth-child(2) {
  border-color: var(--compliment-color);
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.lds-ripple {
  width: 200px !important;
  height: 200px !important;
  -webkit-transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
  transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
}

/* Bootsrap: xs */
@media (max-width: 575px) {
  .lds-ripple {
    width: 100px !important;
    height: 100px !important;
    transform: translate(-50px, -50px) scale(0.5) translate(50px, 50px);
  }
  .ls-loading h1 {
    font-size: 2rem;
  }
}
